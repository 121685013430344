<template>
    <div class="accompanyingList">
        <van-nav-bar
            left-arrow
            left-text="新建申请"
            @click-left="onClickLeft"
            class="topNavBar"
            title="随行人列表"
        />
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <!-- :before-close="beforeClose" -->
            <div class="list">
                <van-swipe-cell v-for="(item,index) in accompanyingList" :key="index">
                    <van-cell :border="false" :title="item.visitorName" :value="item.mobile" />
                    <template slot="right">
                        <van-button @click="delItem(item,index)" square type="danger" text="删除" />
                    </template>
                </van-swipe-cell>
            </div>
        </van-pull-refresh>

        <div class="bottom-operation">
            <!-- <van-button round type="info">圆形按钮</van-button>
            <van-button round type="info">圆形按钮</van-button>-->
            <van-button class="btn" size="small" @click="switchView(0)" round type="primary">添加人员</van-button>
            <van-button
                class="btn"
                size="small"
                round
                @click="switchView(1)"
                type="info"
            >完&ensp;&ensp;成</van-button>
        </div>
    </div>
</template>


<script>
    import { Dialog } from 'vant';
    import {Toast} from 'vant' 
    export default {
        name: "accompanyingList",
        components: {
        },
        data() {
            return {
                accompanyingList:[
                    // {
                    //     visitorName:'鲁智深',
                    //     mobile: '17998650326',
                    // }
                ],
                //控制下拉刷新
                isLoading: false,
            };
        },
        watch: {
            
        },
        mounted() {
            

            if(this.$store.state.visitorApplyData.formData == null){
                this.$router.push({
                    name: "visitor-apply",
                });
            }
            this.accompanyingList  = this.$store.state.visitorApplyData.accompanyingList;
            // this.loadmore();
        },
        methods: {
            onClickLeft(){
                this.$router.push({
                    name: "visitor-apply",
                });
            },
            //生成数据
            loadmore() {
				for (let i = 0; i < 100; i++) {
					this.accompanyingList.push({
						id:  Math.random()*1000,
						visitorName: '鲁智深'+(i+1),
						mobile: '17998650326',
					})
				}
			},

            //下拉刷新
            onRefresh() {
                setTimeout(() => {
                    Toast('刷新成功');
                    this.isLoading = false;
                }, 1000);
            },

            //异步发删除方法
            beforeClose({ position, instance }) {
                switch (position) {
                    case 'left':
                    case 'cell':
                    case 'outside':
                    instance.close();
                    break;
                    case 'right':
                    Dialog.confirm({
                        message: '确定删除吗？'
                    }).then(() => {
                        instance.close();
                        console.log('异步发删除方法',position, instance )

                    }).catch(() => {
                        // on cancel
                    });
                    break;
                }

                // Dialog.confirm({
                //     message: '确定删除吗？'
                // }).then(() => {
                    
                // }).catch(() => {
                //     // on cancel
                // });

                // console.log('异步发删除方法',position, instance )

            },
            //删除一项
            delItem(data,index){
                console.log('删除一项',data,index);

                Dialog.confirm({
                    message: `确定删除随访人员【${data.visitorName}】吗？`
                }).then(() => {
                    this.accompanyingList.splice(index, 1);
                }).catch(() => {
                    // on cancel
                });

                
                // for (let i = 0; i < this.accompanyingList.length; i++) {
				// 	if (this.accompanyingList[i].id == this.party.id) {
				// 		this.accompanyingList.splice(i, 1);
				// 	}
				// }
            },

            //切换视图
            switchView(val){
                
                if(val == 0){
                    this.$router.push({
                        name: "accompanying-add",
                    });
                }else{
                    this.$router.push({
                        name: "visitor-apply",
                    });
                }
                
            },
        },
    };
</script>



<style scoped lang="scss">
/* 解决Vant 左滑删除样式错位问题 2022.6.8 */

.accompanyingList {
    .topNavBar {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
    }
    .list {
        // padding-top: 51px;
        margin-top: 51px;
        padding-bottom: 58px;
        // background: #d9d9d9;
        ::v-deep {
            .van-swipe-cell {
                // background: #d9d9d9;
            }
        }
    }
    .bottom-operation {
        // background: #eee;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #eeeeee;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 10px;
        box-sizing: border-box;
        .btn {
            width: 100px;
        }
    }
}
</style>
