
<template>
    <div class="accompanyingAdd">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="topNavBar" title="添加随行人员" />
        <!-- <p>{{rulesForm.name}}</p> -->
        <div class="formModular">
            <div class="formFrame">
                <van-form ref="formData" @failed="onFailed" :label-width="80" @submit="onSubmit">
                    <!-- 通过 pattern 进行正则校验 -->
                    <!-- rule="name" -->
                    <van-field
                        center
                        required
                        v-model="formData.visitorName"
                        name="visitorName"
                        label="随行姓名"
                        :placeholder="formData.visitorName"
                        :rules="rulesForm.visitorName"
                    />

                    <van-field
                        center
                        v-model="formData.mobile"
                        required
                        name="mobile"
                        label="电话号码"
                        :placeholder="formData.mobile"
                        :rules="rulesForm.mobile"
                    />

                    <!-- <van-field
                        center
                        v-model="formData.documentNo"
                        name="documentNo"
                        label="身份证号"
                        :placeholder="formData.documentNo"
                        :rules="rulesForm.documentNo"
                    />-->

                    <van-field
                        center
                        v-model="formData.visitorOrganizationName"
                        name="visitorOrganizationName"
                        label="单位"
                        :placeholder="formData.visitorOrganizationName"
                    />

                    <!-- 拍照组件 -->
                    <shotModular
                        ref="shotModular"
                        :shotModularText="' '"
                        @getShotModularSrc="getShotModularSrc"
                    />

                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">确认添加</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>


<script>
    import { get,post } from "../../utils/request";
    import {Toast} from 'vant' 

    import shotModular from "../../components/shotModular.vue"; //引入子组件
    export default {
        name: "accompanyingAdd",
        components: {
            shotModular
        },
        data() {
            return {
                
                formData:{
                    visitorName:'',
                    base64Image:null,
                },
                rulesForm:{
                    visitorName:[
                        { required: true, message: '请填写用户名' }
                    ],
                    mobile:[
                        {
                            validator: value => {
                                return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
                                .test(value)
                            },
                            message: '请输入正确的手机号格式',
                            trigger: 'onBlur',
                        }
                    ],

                    documentNo:[
                        {
                            validator: value => {
                                console.log('验证中的方法',value)
                                if(value == ''){
                                    return true
                                }else{
                                    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                                .test(value)
                                }
                            },
                            message: '请输入正确的证件号格式',
                            trigger: 'onBlur',
                        }
                    ],
                }
            };
        },
        watch: {
            
        },
        mounted() {
            if(this.$store.state.visitorApplyData.formData == null){
                this.$router.push({
                    name: "visitor-apply",
                });
            }
            console.log('几次');
            document.title = "丽泽智能科技访客";
        },
        methods: {
            onClickLeft(){
                this.$router.go(-1)
            },
            // 验证通过提交表单
            onSubmit(){
                console.log('验证通过提交表单',this.formData);
                this.$store.state.visitorApplyData.accompanyingList.push(this.formData);
                console.log('验证通过',this.formData,this.$store.state.visitorApplyData.accompanyingList);
                this.$router.push({
                    name: "accompanying-list",
                });
            },
            //验证不通过
            onFailed(){
                console.log('验证不通过');
            },
            
            //获取照片的src
            getShotModularSrc(mediaId){
                this.formData.mediaId = mediaId ;
                console.log('获取照片的src',this.formData.mediaId);
            },

        },
    };
</script>


<style scoped lang="scss">
.accompanyingAdd {
    .formModular {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        .formFrame {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;
        }
    }
}
</style>
