<template>
    <div class="jump"></div>
</template>


<script>
    
    export default {
        name: "jump",
        components: {
        },
        data() {
            return {
                
            };
        },
        watch: {
            
        },
        created(){
            
            window.location.replace("http://192.168.5.191:10006/api/visitor/login");
        },
        mounted() {
            
        },
        methods: {
           
        },
    };
</script>


<style scoped lang="scss">
</style>
