
<template>
    <div class="searchPage">
        <form action="/">
            <van-search
                class="search"
                :autofocus="true"
                v-model="condition"
                show-action
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @cancel="onCancel"
            />
        </form>

        <div class="list">
            <!-- :title="`[ ${item.organizationName} ] ${item.name}`" -->
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <van-cell
                    @click="clickListItem(item)"
                    v-for="(item,index) in list"
                    :key="index"
                    :title="item.name"
                    :value="item.hideMobile"
                    :label="item.organizationName"
                />
            </van-list>
        </div>
    </div>
</template>


<script>
    import {Toast} from 'vant' 
    import { Dialog } from 'vant';

    import { get } from '../../utils/request';
    export default {
        name: "searchPage",
        components: {
        },
        data() {
            return {
                list: [],
                
                finished : true ,//加载状态没有更多了
                loading : true,//加载状态
                condition:'',
            };
        },
        watch: {
            
        },
        mounted() {
            if(this.$store.state.visitorApplyData.formData == null){
                this.$router.push({
                    name: "visitor-apply",
                });
            }
        },
        methods: {

            //点击选择了列表
            clickListItem(rows){
                // console.log('点击选择了列表',rows.id);
                Dialog.confirm({
                    // title: '标题',
                    message: `确认选择被访人【${rows.name}】?`,
                })
                .then(() => {
                    // on confirm
                    this.$store.state.visitorApplyData.formData.userId = rows.id;
                    this.$store.state.visitorApplyData.formData.userName = rows.name;
                    this.$store.state.visitorApplyData.formData.userOrganizationId = rows.organizationId;
                    this.$store.state.visitorApplyData.formData.userOpenId = rows.openId;

                    this.$router.push({
                        name: "next-step",
                    });
                })
                .catch(() => {
                    // on cancel
                });
            },
            //加载数据
           onLoad() {
            // 异步更新数据
            console.log('异步更新数据')
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            // setTimeout(() => {
            //     for (let i = 0; i < 10; i++) {
            //         // this.list.push(i+1);
            //         this.list.push({
            //             name:`张${i}`,
            //             id:i
            //         });
            //     }

            //     // 加载状态结束
            //     this.loading = false;

            //     // 数据全部加载完成
            //     if (this.list.length >= 40) {
            //         this.finished = true;
            //     }
            //     }, 1000);
            },

			onSearch(val) {
                // Toast(val);
                if(val == ''|| val == null){
                    Toast('请输入搜索内容');
                    return false;
                }

                get("/api/visitor/employee-info-list", {
                    queryCondition:val,
                    organizationId:localStorage.getItem('organizationId'),
                })
                    .then((ren) => {
                        var list = ren.data.data;
                        for(let i = 0; i < list.length; i++){
                            let reg = /^(\d{3})\d{4}(\d{4})$/;
                            list[i].hideMobile = list[i].mobile.replace(reg, '$1****$2');
                            console.log("wwwwwwww", list[i].hideMobile);
                        }
                        this.list = list;
                        
                    })
                    .catch((err) => {
                        console.log("xxxxx", err.response);
                    });
                },
            onCancel() {
                // Toast('取消');
                // this.$router.go(-1)

                this.$router.push({
                    name: "next-step",
                });
            },
           
        },
    };
</script>


<style scoped lang="scss">
.searchPage {
    .search {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
    }
    .list {
        padding-top: 50px;
    }
}
</style>
