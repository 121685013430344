<template>
    <div class="Nav">
        <div class="view">
            <!-- <keep-alive include="visitor-apply"> -->
            <router-view>
                <router-view :key="navActive" />
            </router-view>
            <!-- </keep-alive> -->
        </div>
        <div class="navModular">
            <van-tabbar v-model="navActive" @change="onNavChange" route>
                <van-tabbar-item name="visitor-apply" to="/visitor-apply" icon="home-o">访客申请</van-tabbar-item>
                <!-- <van-tabbar-item name="csx" to="/csx" icon="home-o">访客申请</van-tabbar-item> -->
                <van-tabbar-item name="visit-record" to="/visit-record" icon="search">访问记录</van-tabbar-item>

                <van-tabbar-item
                    name="accessed-record"
                    v-if="userType == 'EMPLOYEE'"
                    to="/accessed-record"
                    icon="comment-o"
                >被访记录</van-tabbar-item>

                <van-tabbar-item name="my-profile" to="/my-profile" icon="friends-o">我的信息</van-tabbar-item>
                <!-- <van-tabbar-item name="csx" to="/csx" icon="friends-o">我的信息</van-tabbar-item> -->
            </van-tabbar>
        </div>
    </div>
</template>
<script>
    import {Toast} from 'vant';
    import { get,post,put } from "../utils/request";
    export default {
        name: "Nav",
        components: {
        },
        data() {
            return {
                navActive: 'visitor-apply',
                // reWrite:true,//子组件更新
                userType:"用户类型",//用户类型
            };
        },
        watch: {
            //监听窗口变化信息
            userTypeChange: {
                deep: true,
                handler: function (val, olVal) {
                    if (val != olVal) {
                        console.log('改变',val,olVal);
                        if(val){
                            this.userType = val.type
                        }
                    }
                },
            },
        },
        computed: {
            //从vuex获取窗口变化信息
            userTypeChange() {
                return JSON.parse(JSON.stringify(this.$store.state.myProfileData.formData));
            },
        },
        mounted() {
            this.navActive = this.$route.name;
            // this.userType = this.$store.state.myProfileData.formData.type;
            console.log('导航判断xxxxxxxxxxxxxxxxxxxx',this.$store.state.myProfileData.formData,this.userType)
            // this.userType = 'EMPLOYEE';
            
            if(localStorage.getItem('userType')){
                this.userType = localStorage.getItem('userType');
            }else{
                this.getUserInfo();
            }
            
        },
        methods: {
            //切换导航
            onNavChange(name){
                // console.log('切换导航',name,this.$router.replace);
                // this.$route.path
                // console.log('切换导航',name,this.$route.path);
                this.$store.state.visitListData.state = false;
                this.$store.state.accessedListData.state = false;
                this.$router.push({ name: name });
                // this.$router.replace({ name: name });
            },

            //获取用户信息
            getUserInfo(){
                // console.log("获取用户信息");
                get("/api/visitor/my-info", {
                    // openId: localStorage.getItem('openId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                    organizationId: localStorage.getItem('organizationId'),
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            this.$store.state.myProfileData.formData = ren.data.data;
                            this.userType = ren.data.data.type

                            this.getImgSrc(ren.data.data);
                        }else{
                            Toast.fail(ren.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });
            },


            //获取照片
            getImgSrc(data){
                if(!data.token){
                    data.token = null;
                }
                get("/api/visitor/user-photo", {
                    token: data.token,
                    // token:'6135c765-fa27-41ed-8efd-9acf879de1bf',
                    organizationId:localStorage.getItem('organizationId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            // console.log('获取照片',ren.data.data)
                            if(ren.data.data){
                                this.$store.state.myProfileData.base64ImageSrc = 'data:image/jpeg;base64,'+ ren.data.data;
                            }
                        }else{
                            Toast.fail(ren.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });
            },


        },
    };
</script>


<style scoped lang="scss">
.view {
    padding-bottom: 55px;
}
.Nav {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}
</style>
