export default {
    install(Vue, options) {
        //本地存储方法
        Vue.prototype.handleLocalStorage = function (method, key, value) {
            switch (method) {
                case 'get': {
                    let temp = window.localStorage.getItem(key);
                    if (temp) {
                        return temp
                    } else {
                        return false
                    }
                }
                case 'set': {
                    window.localStorage.setItem(key, value);
                    break
                }
                case 'remove': {
                    window.localStorage.removeItem(key);
                    break
                }
                default: {
                    return false
                }
            }
        }

        //修改时间格式，时间戳改为时分秒
        Vue.prototype.formatToDate = function (dateTime) {
            if (dateTime) {
                const dateMat = new Date(dateTime);
                const Y = dateMat.getFullYear() + "-";
                const M =
                    dateMat.getMonth() + 1 < 10
                        ? "0" + (dateMat.getMonth() + 1) + "-"
                        : dateMat.getMonth() + 1 + "-";
                const D =
                    dateMat.getDate() < 10
                        ? "0" + dateMat.getDate() + " "
                        : dateMat.getDate() + " ";

                const H =
                    dateMat.getHours() < 10
                        ? "0" + dateMat.getHours() + ":"
                        : dateMat.getHours() + ":";

                const F =
                    dateMat.getMinutes() < 10
                        ? "0" + dateMat.getMinutes() + ":"
                        : dateMat.getMinutes() + ":";

                const S =
                    dateMat.getSeconds() < 10
                        ? "0" + dateMat.getSeconds() + " "
                        : dateMat.getSeconds() + "";

                return Y + M + D + H + F + S;
            }
        }

        // 时分秒转为时间戳
        Vue.prototype.timeToSec = (time) => {
            if (time !== null) {
                var s = "";
                var hour = time.split(":")[0];
                var min = time.split(":")[1];
                var sec = time.split(":")[2];
                s = Number(hour * 3600) + Number(min * 60) + Number(sec);
                return s;
            }
        }




        // 数组删除某个指定元素方法
        Vue.prototype.removeArrayEle = function (val, arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == val) {
                    arr.splice(i, 1);
                }
            }
        }

        // 数组去重, arr为要去重的数组
        Vue.prototype.removeDuplicatedItem = function (arr) {
            // console.log(arr)
            var ret = [];
            for (var i = 0, j = arr.length; i < j; i++) {
                if (ret.indexOf(arr[i]) === -1) {
                    ret.push(arr[i]);
                }
            }
            return ret
        }

        //将时间戳转换为年月日
        Vue.prototype.dateTimeStr = function (data) {
            var date = new Date(data)

            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';

            var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
            // var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
            var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
            // var strDate = Y + M + D + h + m + s;
            var strDate = Y + M + D + h + m;
            return strDate;
        }


        //截取URL里的里的参数
        Vue.prototype.getRequest = function (parName) {
            var str = parName.toLowerCase() + "=";
            var gvalue = null;
            var HREF = location.href;
            var upperHREF = HREF.toLowerCase();
            if (upperHREF.indexOf(str) > 0) {
                gvalue = HREF.substring(upperHREF.indexOf(str) + str.length, upperHREF.length);
                if (gvalue.indexOf('&') > 0) gvalue = gvalue.substring(0, gvalue.indexOf('&'));
                if (gvalue.indexOf("#") > 0) gvalue = gvalue.split("#")[0];
                gvalue = decodeURIComponent(gvalue)
            }
            return gvalue;
        }

    }
}