<template>
    <div class="visitRecord">
        <!-- <button @click="visitDetails">测试按钮</button> -->
        <van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
            <!-- :offset="300" -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad()"
                class="list"
            >
                <!-- <van-swipe-cell class="itemCell" @click.native="visitDetails">
                    <div class="mask">
                        <div class="content">
                            <div class="head">
                                <span class="title">访问记录 ></span>
                                <span class="state yellow">审核中</span>
                            </div>
                            <div class="body">
                                <ul class="ul">
                                    <li class="li">
                                        <span class="key">被访人员：</span>
                                        <span class="val">马化腾</span>
                                    </li>
                                    <li class="li">
                                        <span class="key">访客人数：</span>
                                        <span class="val">1人</span>
                                    </li>
                                    <li class="li">
                                        <span class="key">开始时间：</span>
                                        <span class="val">2022-12-18 15:30</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <template #right>
                        <van-button
                            square
                            @click.stop="handleApproval(null,'agree')"
                            class="btn"
                            type="primary"
                            text="同意"
                        />
                        <van-button
                            square
                            @click.stop="handleApproval(null,'refuse')"
                            text="拒绝"
                            type="danger"
                            class="btn delete-button"
                        />
                    </template>
                </van-swipe-cell>-->
                <!-- :disabled="item.visitorId == userId" -->
                <van-swipe-cell
                    class="itemCell"
                    v-for="(item,index) in listData"
                    :key="index"
                    @click.native="visitDetails(item)"
                    :disabled="item.userId == userId"
                >
                    <div class="mask">
                        <div class="content">
                            <div class="head">
                                <span class="title" v-if="item.userId == userId">被访记录 ></span>
                                <span class="title" v-else>访问记录 ></span>
                                <!-- <span class="state yellow">审核中</span> -->
                                <!-- 1：待审批/审批中；2：审批撤销；3：审批过期；4：审批拒绝；5：审批通过 -->
                                <span v-if="item.status == 1" class="state yellow">审核中</span>
                                <span v-if="item.status == 2" class="state">已撤销</span>
                                <span v-if="item.status == 3" class="state">审批过期</span>
                                <span v-if="item.status == 4" class="state red">已拒绝</span>
                                <span v-if="item.status == 5" class="state green">已通过</span>
                            </div>
                            <div class="body">
                                <ul class="ul">
                                    <li class="li">
                                        <span class="key">主访问人：</span>
                                        <span class="val">{{item.visitorName}}</span>
                                    </li>
                                    <li class="li">
                                        <span class="key">被访人员：</span>
                                        <span class="val">{{item.userName}}</span>
                                    </li>
                                    <li class="li">
                                        <span class="key">访客人数：</span>
                                        <span class="val">{{item.groupSize}}人</span>
                                    </li>
                                    <li class="li">
                                        <span class="key">开始时间：</span>
                                        <span class="val">{{formatDateTime(item.startTime)}}</span>
                                    </li>
                                </ul>
                            </div>

                            <i v-if="item.status == 1" class="icon iconfont yellow">&#xe54a;</i>
                            <i v-if="item.status == 2" class="icon iconfont">&#xe532;</i>
                            <i v-if="item.status == 3" class="overtime icon iconfont">&#xe642;</i>
                            <i v-if="item.status == 4" class="icon iconfont red">&#xe527;</i>
                            <i v-if="item.status == 5" class="icon iconfont green">&#xe526;</i>
                        </div>
                    </div>

                    <template #right>
                        <van-button
                            square
                            :disabled="item.status == 5 || item.status == 4"
                            @click.stop="handleApproval(item,'agree')"
                            class="btn"
                            type="primary"
                            text="同意"
                        />
                        <van-button
                            square
                            :disabled="item.status == 5 || item.status == 4"
                            @click.stop="handleApproval(item,'refuse')"
                            text="拒绝"
                            type="danger"
                            class="btn delete-button"
                        />
                    </template>
                </van-swipe-cell>
            </van-list>
        </van-pull-refresh>
    </div>
</template>


<script>
    import { Dialog } from 'vant';
    import {Toast} from 'vant'
    import {get,put} from "../../utils/request";
    export default {
        name: "visitRecord",
        components: {
        },
        data() {
            return {
                //控制下拉刷新
                isLoading: false,

                loading: false,
                finished: false,

                // finished: true,

                listData:[],

                openId: null,
                organizationId:null,
                userId:null,
                //分页信息
                pageInfo:{
                    pageNo:0,
                    pageSize:5,
                }


            };
        },
        watch: {

        },

        
        mounted() {

            this.openId = localStorage.getItem('openId');
            this.organizationId = localStorage.getItem('organizationId');

            this.userId = localStorage.getItem('userId');
            // this.userId = "61956e61-f93d-4c43-ade5-fb0f39b4bc67";

            // console.log('能不能拿到用户的ID',userId,localStorage.getItem('userId'));
             if(!this.$store.state.accessedListData.state){
                this.listData = this.$store.state.accessedListData.listData;
             }else{
                // this.onLoad();
             }
            // console.log('列表页加载完毕',this.$store.state.accessedListData.state,'--userId--',this.userId);
            console.log('列表页加载完毕 被访记录',this.$store.state.accessedListData.state,'--listData--',this.$store.state.accessedListData.listData);
        },
        methods: {
            //修改时间格式
            formatDateTime(date) {
                if (date == "" || !date) {
                    return "";
                }
                var date = new Date(date);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                var second = date.getSeconds();
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second; 
            },

            //获取data
            getListData(){
                let obj ={
                    organizationId: this.organizationId,
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',

                    pageNo:1,
                    pageSize:this.pageInfo.pageSize,
                }
                get("/api/visitor/interviewed-record-list", obj)
                    .then((ren) => {
                        console.log("刷新数据获取成功",this.userId, ren.data.data);

                        this.listData = ren.data.data;

                        this.loading = false;
                        this.isLoading = false;

                        if(this.listData.length < 5){
                            this.pageInfo.pageNo = 0;
                            this.finished = true;
                        }else{
                            this.pageInfo.pageNo = 1;
                            this.finished = false;
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });

            },
            //下拉刷新
            onRefresh() {
                console.log('下拉刷新');
                this.$store.state.accessedListData.state = true;
                // this.pageInfo.pageNo = 0;
                // this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.getListData();

            },
            //触底加载数据
            onLoad() {
                console.log("WWWWWWWWWWW",this.$store.state.accessedListData.state,this.listData);
                this.finished = false;
                this.isLoading = false;
                if(!this.$store.state.accessedListData.state && this.listData.length != 0){
                    this.listData = this.$store.state.accessedListData.listData;
                    console.log('不重新获取列表数据')
                    this.finished = true;
                }else{
                    if(this.listData.length != 0){
                        // this.pageInfo.pageNo = 1;
                    }else{
                        this.pageInfo.pageNo = 0;
                    }
                    this.pageInfo.pageNo = this.pageInfo.pageNo + 1;
                    console.log('触底加载数据');
                    setTimeout(() => {
                      let obj ={
                            organizationId: this.organizationId,
                            // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',

                            pageNo:this.pageInfo.pageNo,
                            pageSize:this.pageInfo.pageSize,
                            // pageNo:1,
                            // pageSize:2,
                        }
                        get("/api/visitor/interviewed-record-list", obj)
                            .then((ren) => {
                                console.log("触底数据获取成功",this.userId, ren.data.data,this.isLoading);

                                if (this.isLoading) {
                                    this.listData = [];
                                    this.isLoading = false;
                                }

                                for(let i = 0; i < ren.data.data.length; i++){
                                    this.listData.push(ren.data.data[i]);
                                }
                                this.$store.state.accessedListData.listData = this.listData;
                                this.loading = false;
                                if( ren.data.data.length < 5){
                                    this.pageInfo.pageNo = this.pageInfo.pageNo - 1;
                                    this.finished = true;
                                }
                            })
                            .catch((err) => {
                                this.finished = true;
                                console.log("错误信息", err);
                            });

                    }, 300);
                }

                // this.pageInfo.pageNo = this.pageInfo.pageNo + 1;
                // console.log('触底加载数据');
                // setTimeout(() => {
                //   let obj ={
                //         // organizationId: this.organizationId,
                //         organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',

                //         pageNo:this.pageInfo.pageNo,
                //         pageSize:this.pageInfo.pageSize,
                //     }
                //     get("/api/visitor/interviewed-record-list", obj)
                //         .then((ren) => {
                //             console.log("wwwwwww", ren.data.data,this.isLoading);

                //             if (this.isLoading) {
                //                 this.listData = [];
                //                 this.isLoading = false;
                //             }

                //             for(let i = 0; i < ren.data.data.length; i++){
                //                 this.listData.push(ren.data.data[i]);
                //             }
                //             this.loading = false;
                //             if( ren.data.data.length < 5){
                //                 this.pageInfo.pageNo = this.pageInfo.pageNo - 1;
                //                 this.finished = true;
                //             }
                //         })
                //         .catch((err) => {
                //             this.finished = true;
                //             console.log("错误信息", err);
                //         });

                // }, 2000);
            },

            //访问详情
            visitDetails(data){
                // console.log('userId:',this.userId,'--- visitorId:',data,data.visitorId)
                this.$store.state.visitRecordData.formData = data;

                console.log('访问详情:',this.$store.state.visitRecordData.formData)
                // if(this.userId != data.visitorId){
                //     this.$store.state.visitRecordData.state = 1;
                // }else{
                //     console.log('我自己的请求');
                //     this.$store.state.visitRecordData.state = 2;
                // }
                this.$router.push({
                    name: "visit-details",
                });
            },
            // 直接审批处理
            handleApproval(data,val){
                console.log('直接审批处理',data);

                let text = '同意';
                let status = JSON.parse(JSON.stringify(data.status));

                data.organizationId = this.organizationId;

                if(val =='agree'){
                    text = '同意';
                }else{
                    text = '拒绝';
                }

                Dialog.confirm({
                    message: `确定【 ${text} 】${data.visitorName}访问申请吗？`
                }).then(() => {

                    if(val =='agree'){
                        data.status = 5;
                    }else{
                        data.status = 4;
                    }

                    put("/api/visitor/process-visiting-application", data)
                        .then((res) => {
                            if (res.data.code == 200) {
                                console.log('直接审批处理2222',res.data.code)
                            } else {
                                console.log('不是200')
                                data.status = status;
                            }
                        })
                        .catch((err) => {
                            console.log('请求错误')
                            data.status = status;
                        });

                }).catch(() => {
                    // on cancel
                    console.log('!!!')
                    // data.status = status;
                });
            }
        },
    };
</script>



<style scoped lang="scss">
.visitRecord {
    .refresh {
        // background: #f56c6c;
        min-height: calc(100vh - 90px);
        // padding-top: 20px;
        padding-top: 10px;
    }
    .list {
        min-height: calc(100vh - 90px);
        // overflow: hidden;
        // padding-top: 10px;
        // box-sizing: border-box;
        .itemCell {
            .mask {
                padding: 0 10px;
                box-sizing: border-box;
                margin-bottom: 10px;

                // box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.029),
                //     6.7px 6.7px 5.3px rgba(0, 0, 0, 0.041),
                //     12.5px 12.5px 10px rgba(0, 0, 0, 0.048),
                //     22.3px 22.3px 17.9px rgba(0, 0, 0, 0.054),
                //     41.8px 41.8px 33.4px rgba(0, 0, 0, 0.06),
                //     100px 100px 80px rgba(0, 0, 0, 0.07);

                .content {
                    padding: 0 10px;
                    border: 1px solid #c9c8c8;
                    box-sizing: border-box;
                    border-radius: 5px;
                    position: relative;
                    overflow: hidden;
                    .icon {
                        font-size: 90px;
                        position: absolute;
                        top: 45px;
                        right: 10px;

                        &.yellow {
                            //审核中
                            color: #e6a23c;
                        }
                        &.green {
                            //通过
                            color: #67c23a;
                        }
                        &.red {
                            color: #f56c6c;
                        }
                        &.overtime {
                            bottom: -22px;
                            right: -19px;
                            font-size: 100px;
                            transform: rotate(-48deg);
                        }
                    }
                    .head {
                        height: 35px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #eeeeee;
                        box-sizing: border-box;
                        .title {
                            font-size: 15px;
                            color: #000;
                            font-weight: bold;
                        }
                        .state {
                            font-size: 14px;
                            &.yellow {
                                //审核中
                                color: #e6a23c;
                            }
                            &.green {
                                //通过
                                color: #67c23a;
                            }
                            &.red {
                                color: #f56c6c;
                            }
                        }
                    }
                    .body {
                        .ul {
                            padding: 10px 0;
                            .li {
                                display: flex;
                                padding: 5px 0px;
                                .key {
                                    font-size: 14px;
                                }
                                .val {
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
            .btn {
                height: 100%;
            }
        }
    }

    ::v-deep {
        .van-swipe-cell__right {
            background: #fff;
        }
        // .delete-button {
        //     height: 100%;
        // }
    }
}
</style>
