import axios from 'axios'
// import store from "../store";
//引入自己封装的cookie方法
// import cookie from '../utils/cookie'


//接收部门ip的方法
// export function depaIp(url){
//     return axios.get(url,{
//         params
//     });
// }


export function get(url, params) {
    return axios.get(url, {
        params
    });
}




export function post(url, data, params) {
    return axios.post(url, data, params);
}


export function remove(url, data, params) {
    return axios.delete(url, data, params);
}



export function put(url, data, params) {
    return axios.put(url, data, {
        params
    });
}


// 当后台返回数据时，我们只需要渲染到页面上的数据，所以我们在响应的时候进行拦截，并返回需要的数据
axios.interceptors.response.use(response => {
    let res = response;         // 拿到响应回来的数据
    // console.log('我是响应拦截', res, res.data.cod, res.config.method)

    // if (res.data.code == 2001 || res.data.code == 2002) {
    //     console.log('你得跳到登录哇', res.data.code, res);
    //     cookie.clearCookie('giacpToken')
    //     window.location.href = '/';
    // } else {
    //     return response;
    // }

    return response;

}, err => {
    // console.log('响应报错',err.response.status)
    if (err && err.response) {

        switch (err.response.status) {
            case 400:
                // err.message = '请求错误(400)' ; 
                Message.error('请求错误(400)')
                break;

            case 401:
                // err.message = '未授权，请重新登录(401)';
                Message.error('未授权，请重新登录(401)')
                break;

            case 403:
                // err.message = '拒绝访问(403)'; 
                Message.error('拒绝访问(403)')
                break;

            case 404:
                // err.message = '请求出错(404)'; 
                Message.error('请求出错(404)')
                break;

            case 408:
                // err.message = '请求超时(408)'; 
                Message.error('请求超时(408)')
                break;

            case 500:
                // err.message = '服务器错误(500)'; 
                Message.error('服务器错误(500)')
                break;

            case 501:
                // err.message = '服务未实现(501)'; 
                Message.error('服务未实现(501)')
                break;

            case 502:
                // err.message = '网络错误(502)'; 
                Message.error('网络错误(502)')
                break;

            case 503:
                console.log('来到', err.response.status, Message)
                Message.error('服务不可用(503)')
                break;

            case 504:
                // err.message = '网络超时(504)'; 
                Message.error('网络超时(504)')
                break;

            case 505:
                // err.message = 'HTTP版本不受支持(505)'; 
                Message.error('HTTP版本不受支持(505)')
                break;

            // default: err.message = `连接出错(${err.response.status})!`;
            default: Message.error(`连接出错(${err.response.status})!`);
        }
    } else {
        err.message = '连接服务器失败!'
    }
    message.error(err.message);
    return Promise.reject(err);
})

