<template>
    <div class="lose">
        <p class="lose-title">404</p>
        <div class="content">
            <p class="text text1">服务器好像出了点小问题...</p>
            <p class="text text2">请稍后再试~</p>
        </div>

        <!-- <van-button type="primary" to="my-profile">返回我的信息</van-button> -->
    </div>
</template>


<script>
export default {
    name: "lose",
    components: {},
    data() {
        return {
                
        };
    },
    watch: {},
    mounted() {
        
        
    },
    methods: {
            

    },
};
</script>

<style scoped lang="scss">
.lose {
    .lose-title {
        font-size: 48px;
    }
    .content {
        // margin: 10px 0;
        padding: 0 20px;
        text-align: left;
        .text {
            margin: 0;
            font-size: 30px;
            margin-bottom: 20px;
        }
    }
}
</style>


