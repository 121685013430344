<template>
    <div class="visitorApply">
        <van-nav-bar title="访客申请" />
        <!-- 拍照组件 -->
        <div class="shotModular">
            <div class="top">
                <div class="exhibition" @click="handlePhotograph" :key="timer">
                    <van-image
                        v-if="formData.imagSrc != null"
                        fit="fill"
                        :src="formData.imagSrc[0]"
                    />

                    <van-icon v-else class="icn" name="photograph" />
                    <!-- <van-icon class="icn" name="photograph" /> -->
                </div>
            </div>
            <!-- <div class="explain">请点击拍摄人脸照片</div> -->
        </div>

        <div class="formModular">
            <div class="formFrame">
                <van-form @failed="onFailed" :label-width="80" @submit="onSubmit">
                    <!-- 通过 pattern 进行正则校验 -->
                    <van-field
                        required
                        center
                        v-model="formData.name"
                        name="name"
                        label="访客姓名"
                        :placeholder="formData.name"
                        :rules="rulesForm.name"
                    />

                    <!-- <van-field
                        required
                        center
                        v-model="formData.age"
                        name="age"
                        label="访客年龄"
                        :placeholder="formData.age"
                        :rules="rulesForm.age"
                    />-->

                    <van-field
                        v-model="formData.documentNo"
                        name="documentNo"
                        label="身份证号"
                        :placeholder="formData.documentNo"
                        :rules="rulesForm.documentNo"
                    />
                    <van-field
                        v-model="formData.mobile"
                        name="mobile"
                        label="电话号码"
                        :placeholder="formData.mobile"
                        :rules="rulesForm.mobile"
                    />

                    <van-field
                        v-model="formData.licensePlateNumber"
                        name="licensePlateNumber"
                        label="来访车牌"
                        :placeholder="formData.licensePlateNumber"
                        :rules="rulesForm.licensePlateNumber"
                    />

                    <van-field
                        v-model="formData.visitorOrganizationName"
                        name="visitorOrganizationName"
                        label="来访单位"
                        :placeholder="formData.visitorOrganizationName"
                    />

                    <van-field
                        clickable
                        readonly
                        v-model="formData.followNumber"
                        name="followNumber"
                        label="随行人数"
                        :placeholder="formData.followNumber"
                        right-icon="arrow"
                        @click="switchView"
                    />

                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">新建申请</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>

    import shotModular from "../shotModular.vue"; //引入子组件
    // import wx from 'weixin-js-sdk'
    import {get} from "../../utils/request";
    import {Toast} from 'vant';
    export default {
        name: "visitorApply",
        components: {
            shotModular
        },
        data() {
            return {
                formData:{
                    imagSrc:null,
                    name:'',
                    documentNo:null,
                    mobile:null,
                    visitorOrganizationName:null,
                    followNumber:'0',
                    licensePlateNumber:null,

                },
                rulesForm:{
                    name:[
                        { required: true, message: '请填写访客姓名' }
                    ],
                    age:[
                        { required: true, message: '请填写访客年龄' }
                    ],
                    mobile:[
                        {
                            validator: value => {
                                if(value == ''){
                                    return true
                                }else{
                                //     return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
                                // .test(value)

                                return /^(?:(?:\+|00)86)?1\d{10}$/
                                .test(value)
                                }
                            },
                            message: '请输入正确的手机号格式',
                            trigger: 'onBlur',
                        }
                    ],

                    documentNo:[
                        {
                            validator: value => {
                                console.log('验证中的方法',value)
                                if(value == '' || value == null){
                                    return true
                                }else{
                                    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                                .test(value)
                                }
                            },
                            message: '请输入正确的证件号格式',
                            trigger: 'onBlur',
                        }
                    ],
                    licensePlateNumber:[
                        {
                            validator: value => {
                                if(value == '' || value == null){
                                    return true
                                }else{
                                    return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
                                .test(value)
                                }
                            },
                            message: '请输入正确的车牌号格式',
                            trigger: 'onBlur',
                        }
                    ],


                },

                organizationId:null,
                // imagSrc:null,
                timer:null,//刷新数据
            };
        },
        watch: {

        },
        mounted() {
            console.log('$store-Apply',this.$store.state.visitorApplyData,this.$store.state.visitorApplyData.formData);

            if(this.$store.state.visitorApplyData.formData != null){
                this.formData = JSON.parse(JSON.stringify(this.$store.state.visitorApplyData.formData));
                this.formData.imagSrc = [];
                if(this.$store.state.visitorApplyData.base64ImageSrc != null){
                    // this.formData.imagSrc = ['data:image/jpeg;base64,' + this.$store.state.visitorApplyData.base64ImageSrc];
                    if(Array.isArray(this.$store.state.visitorApplyData.base64ImageSrc)){
                        this.formData.imagSrc = this.$store.state.visitorApplyData.base64ImageSrc;
                        
                    }else{
                        this.formData.imagSrc = ['data:image/jpeg;base64,' + this.$store.state.visitorApplyData.base64ImageSrc];
                    }
                }
            }else{

                let organizationId = localStorage.getItem('organizationId');

                let openId = localStorage.getItem('openId');

                if(openId){
                    localStorage.setItem("openId", openId); 
                }

                if(organizationId){
                    localStorage.setItem("organizationId", organizationId); //存储组织ID
                }

                this.getUserInfo();

            }
            this.formData.followNumber = `${this.$store.state.visitorApplyData.accompanyingList.length}`;



            
            // console.log('几次',this.formData,this.$store.state.visitorApplyData.formData);

            // let organizationId = localStorage.getItem('organizationId');

            // let openId = localStorage.getItem('openId');

            // if(organizationId){
            //     localStorage.setItem("organizationId", organizationId); //存储组织ID
            // }

            // if(openId){
            //     localStorage.setItem("openId", openId);
            // }

            this.getConfig();
            // this.getUserInfo();
        },
        methods: {

            //获取配置信息
            getConfig(){
                console.log('----------',location.href,location.href.split('#')[0],location.href.split('#'))
                let url = location.href.split('#')[0];
                get("/api/authentication/js-sdk" ,
                {
                    url:url,
                    organizationId:localStorage.getItem('organizationId')
                })
                .then((ren) => {
                    // console.log('请求数据成功',ren.data.data);
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。  
                        appId: ren.data.data.appId, // 必填，公众号的唯一标识
                        timestamp: ren.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: ren.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: ren.data.data.signature,// 必填，签名，见附录1
                        jsApiList: ['chooseImage','uploadImage']
                    });
                })
                .catch((err) => {
                    console.log("请求数据失败", err);
                });
            },


            //获取用户信息
            getUserInfo(){
                // console.log("获取用户信息");
                get("/api/visitor/my-info", {
                    openId: localStorage.getItem('openId'),
                    // type: localStorage.getItem('userType'),
                    organizationId: localStorage.getItem('organizationId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                })
                    .then((ren) => {

                        this.formData = ren.data.data;
                        // this.formData.visitorName = ren.data.data.name;
                        // this.formData.visitorName = this.formData.name;
                        // this.formData.age = "15";
                        this.formData.followNumber = `${this.$store.state.visitorApplyData.accompanyingList.length}`;
                        console.log("获取用户信息",this.formData,ren);

                        localStorage.setItem("userId", this.formData.userId); //存储顶userId
                        this.formData.openId = localStorage.getItem('openId');

                        if(this.formData.visitorOrganizationName == null){
                            this.formData.visitorOrganizationName = this.formData.organizationName;
                        }

                        this.$store.state.visitorApplyData.formData = JSON.parse(JSON.stringify(this.formData));

                        this.getImgSrc(ren.data.data);
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });

            },


            //获取照片
            getImgSrc(data){
                get("/api/visitor/user-photo", {
                    token: data.token,
                    organizationId:localStorage.getItem('organizationId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            console.log('多多多多',ren.data)
                            // this.base64ImageSrc =  ren.data.data;
                            if(ren.data.data){
                                this.formData.imagSrc = ['data:image/jpeg;base64,'+ ren.data.data];
                            }else{
                                this.formData.imagSrc = [];
                            }

                            this.$store.state.visitorApplyData.base64ImageSrc = ren.data.data;
                            this.timer = !this.timer;
                        }else{
                            Toast.fail('网络错误');
                            this.base64ImageSrc = null;
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });
            },

            //点击打开了摄像头
            handlePhotograph(){
                wx.ready(()=>{
                    wx.chooseImage({
                        count: 1,
                        sizeType: ['original', 'compressed'],
                        sourceType: ['album', 'camera'],
                        success: (res) => {
                            // tempFilePath可以作为 img 标签的 src 属性显示图片
                            this.formData.imagSrc = res.localIds;
                            this.$store.state.visitorApplyData.base64ImageSrc = this.formData.imagSrc;
                            console.log("-----------------------",res,this.formData.imagSrc);
                            this.timer = !this.timer;
                            this.uploadImage();
                        },
                        fail: (error) => {
                            this.formData.imagSrc = null;
                            console.log("获取人脸照片失败：",error)
                        }
                    })
                });
            },

            //上传
            uploadImage(){
                var that = this;
                wx.uploadImage({
                    localId: this.formData.imagSrc.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        let mediaId = res.serverId; // 返回图片的服务器端ID
                        console.log('成功功功功功功功功',res,mediaId);
                        that.formData.mediaId = mediaId;
                    },
                    fail: function (res) {
                        // weui.toast('上传人脸图片失败，请重试:' + res);
                        console.log('上传人脸图片失败，请重试',res)
                        return;
                    }
                });
            },

            //获取照片的src
            getShotModularSrc(mediaId){
                this.formData.mediaId = mediaId;
                console.log('获取照片的src',this.formData.mediaId);
            },

            // 验证通过提交表单
            onSubmit(){
                this.formData.imagSrc == null;
                if(!this.formData.imagSrc){
                    Toast.fail('请上传验证照片。');
                    return false;
                }
                this.formData.visitingUserRecordList = JSON.parse(JSON.stringify(this.$store.state.visitorApplyData.accompanyingList));

                this.formData.groupSize = this.$store.state.visitorApplyData.accompanyingList.length;
                this.$store.state.visitorApplyData.formData = JSON.parse(JSON.stringify(this.formData));

                console.log('验证通过提交表单',this.formData);

                this.$router.push({
                    name: "next-step",
                });

            },
            //验证不通过
            onFailed(){
                console.log('验证不通过');
            },
            //切换视图
            switchView(){
                console.log('切换视图111',this.formData);
                // debugger
                // this.formData = {...this.$store.state.visitorApplyData.formData,...this.formData}//后面会覆盖掉前面的
                // console.log('切换视图',this.formData);
                this.$store.state.visitorApplyData.formData = JSON.parse(JSON.stringify(this.formData));
                this.$router.push({
                    name: "accompanying-list",
                });
            },

        },
    };
</script>


<style scoped lang="scss">
.visitorApply {
    .shotModular {
        .top {
            display: flex;
            justify-content: center;
            padding: 10px 0;
            padding-top: 40px;
            .exhibition {
                background: #eee;
                width: 120px;
                height: 120px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                .icn {
                    font-size: 56px;
                    line-height: 120px;
                }
            }
        }
        .explain {
            font-size: 16px;
        }
    }
    .formModular {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .formFrame {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;
        }
    }
}
</style>
