<template>
    <div class="visitDetails">
        <van-nav-bar left-arrow left-text="访问记录" @click-left="onClickLeft" title="访问详情" />

        <!-- <div class="photo">
            <div class="exhibition" @click="previewImg(base64ImageSrc)">
                <img v-if="base64ImageSrc != null" class="img" :src="base64ImageSrc" alt />
                <span v-if="base64ImageSrc == null" class="explain">未上传照片</span>
                <van-icon class="icon" v-else name="photo-o" />
            </div>
        </div>-->

        <div class="formModular">
            <div class="formFrame">
                <van-form :label-width="80">
                    <!-- 通过 pattern 进行正则校验 -->
                    <van-field readonly center v-model="formData.visitorName" name="visitorName" label="访客姓名">
                        <template #button>
                            <van-button native-type="button" class="btn" size="mini" type="info" @click="watchPhoto"
                                :loading="photoLoading" loading-text="获取中...">查看照片</van-button>
                        </template>
                    </van-field>

                    <van-field readonly v-model="formData.documentNo" name="documentNo" label="身份证号" />
                    <van-field readonly v-model="formData.mobile" name="mobile" label="电话号码" />

                    <van-field readonly v-model="formData.visitorOrganizationName" name="visitorOrganizationName"
                        label="来访单位" />

                    <van-field readonly v-model="formData.licensePlateNumber" name="licensePlateNumber" label="来访车牌" />

                    <van-field readonly v-model="formData.purpose" name="purpose" label="来访事由" />

                    <van-field readonly v-model="startTime" name="startTime" label="开始时间" />

                    <van-field readonly v-model="endTime" name="endTime" label="结束时间" />

                    <van-field @click="switchView(formData.groupSize)" readonly v-model="formData.groupSize"
                        name="groupSize" label="随访人数" right-icon="arrow" clickable />
                    <van-field readonly v-if="pageState == 2 && formData.status == 4" v-model="formData.userRemark" rows="2"
                        autosize label="拒绝理由" type="textarea" maxlength="50" placeholder="拒绝理由" show-word-limit />
                </van-form>
                <div class="state">
                    <i v-if="formData.status == 1" class="icon iconfont yellow">&#xe54a;</i>
                    <i v-if="formData.status == 2" class="icon iconfont">&#xe532;</i>
                    <i v-if="formData.status == 3" class="overtime icon iconfont">&#xe642;</i>
                    <i v-if="formData.status == 4" class="icon iconfont red">&#xe527;</i>
                    <i v-if="formData.status == 5" class="icon iconfont green">&#xe526;</i>
                </div>
            </div>
        </div>

        <!-- <div class="state" v-if="pageState == 2">
            <i v-if="formData.status == 1" class="icon iconfont yellow">&#xe54a;</i>
            <i v-if="formData.status == 2" class="icon iconfont">&#xe532;</i>
            <i v-if="formData.status == 3" class="overtime icon iconfont">&#xe642;</i>
            <i v-if="formData.status == 4" class="icon iconfont red">&#xe527;</i>
            <i v-if="formData.status == 5" class="icon iconfont green">&#xe526;</i>
        </div>-->

        <!-- 签到按钮 -->
        <div class="reportBtn" v-if="!formData.checkInDisabled && formData.checkInEnable && formData.status == 5">
            <!-- <div class="reportBtn"> -->
            <van-button type="info" :disabled="reportBtnDisabled" @click="handleReport(reportBtnText)" class="btn" block>
                <van-count-down v-if="reportTimeShow" @finish="reportFinish" :time="handleReportTime" />
                {{ reportBtnText }}
            </van-button>
        </div>

        <van-dialog v-model="remarks.dialogShow" @confirm="handleApproval('refuse')" show-cancel-button>
            <van-field v-model="remarks.remarksText" rows="2" autosize type="textarea" maxlength="50" placeholder
                show-word-limit />
        </van-dialog>

        <div class="approval" v-if="pageState == 1">
            <div class="command">
                <van-button class="btn" v-if="formData.status == 1" @click="handleRefuse" type="danger">拒 绝</van-button>
                <van-button class="btn" v-if="formData.status == 1" @click="handleApproval('agree')" type="info">同
                    意</van-button>

                <van-button class="btn" v-if="formData.status == 4" disabled type="danger">已 拒 绝</van-button>
                <van-button class="btn" v-if="formData.status == 5" disabled type="info">已 通 过</van-button>
            </div>
        </div>
    </div>
</template>


<script>
import { Dialog } from 'vant';
import { Toast } from 'vant'
import { ImagePreview } from 'vant';
import { get, put } from "../../utils/request";
export default {
    name: "visitDetails",
    components: {},
    data() {
        return {
            formData: {
                visitorName: '',
                mobile: null,
                documentNo: null,
                followNumber: '0',
            },

            base64ImageSrc: null,
            startTime: null,
            endTime: null,
            showAmount: false,
            //备注
            remarks: {
                dialogShow: false,//备注弹窗
                remarksText: "", //备注内容
            },
            //页面状态
            pageState: 1,
            //访客ID
            visitingRecordId: null,
            //访客类型
            type: null,

            //签到按钮的禁用状态
            reportBtnDisabled: true,
            //签到按钮倒计时
            handleReportTime: 30 * 60 * 60 * 1000,
            //控制倒计时是否展示
            reportTimeShow: false,
            //签到按钮的文字
            reportBtnText: '签到',

            //查看照片按钮加载状态
            photoLoading: false,
            //用户自己的ID
            userId: null,
        };
    },
    watch: {},
    mounted() {

        this.visitingRecordId = this.getRequest('visitingRecordId');

        this.type = this.getRequest('type');
        let organizationId = this.getRequest('organizationId');
        if (organizationId) {
            localStorage.setItem("organizationId", organizationId); //存储组织ID
        }
        // console.log('----------拿到的数据-',this.$store.state.visitRecordData.formData,this.visitingRecordId);
        let cacheFormData = JSON.parse(JSON.stringify(this.$store.state.visitRecordData.formData))
        // debugger

        // this.userId = localStorage.getItem('userId');
        // console.log('userId-userId-userId',this.userId)
        // if(this.userId == null){
        //     this.getUserInfo();
        // }



        // this.pageState = this.$store.state.visitRecordData.state;

        if (cacheFormData == null && this.visitingRecordId == null) {
            this.onClickLeft();
        } else {
            if (this.getRequest('visitingRecordId')) {
                this.visitingRecordId = this.getRequest('visitingRecordId');
            } else {
                this.visitingRecordId = cacheFormData.id;
            }
            this.getUserInfo();
        }
    },
    methods: {
        //请求接口获取表单信息
        getFormData() {
            get("/api/visitor/visiting-record-by-id", {
                visitingRecordId: this.visitingRecordId,
                organizationId: localStorage.getItem('organizationId'),
                // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                type: this.type,
            })
                .then((ren) => {
                    this.formData = ren.data.data;
                    this.getImgSrc(ren.data.data);
                    console.log('拿到什么状态', this.formData.checkInStatus, this.formData)
                    if (ren.data.data == null) {
                        Dialog.alert({
                            message: '该条记录以被删除',
                        }).then(() => {
                            this.onClickLeft();
                        });
                    }
                    if (this.formData.groupSize) {
                        this.formData.groupSize = this.formData.groupSize - 1;
                    }

                    this.startTime = this.formatDateTime(this.formData.startTime);
                    this.endTime = this.formatDateTime(this.formData.endTime);

                    //    console.log('比对对对对对对对对对对',this.userId,this.formData.visitorId,(this.userId == this.formData.visitorId))
                    if (this.userId != this.formData.visitorId) {
                        console.log('别人的请求--', this.userId, this.formData.visitorId);
                        this.pageState = 1;
                    } else {
                        console.log('我自己的请求--', this.userId, this.formData.visitorId);
                        this.pageState = 2;
                    }

                    if (this.formData.checkInStatus == 0) {
                        //按钮不禁用
                        this.reportBtnDisabled = true;
                        //倒计时组件的显示
                        this.reportTimeShow = false;
                        this.reportBtnText = '未审核';
                    } else if (this.formData.checkInStatus == 1) {
                        //按钮不禁用
                        this.reportBtnDisabled = false;
                        //倒计时组件的显示
                        this.reportTimeShow = false;
                        this.reportBtnText = '签到';

                    } else if (this.formData.checkInStatus == 2 || this.formData.checkInStatus == 3) {
                        //按钮不禁用
                        this.reportBtnDisabled = false;
                        //倒计时组件的显示
                        this.reportTimeShow = false;
                        this.reportBtnText = '签离';

                    } else if (this.formData.checkInStatus == 4) {
                        //按钮不禁用
                        this.reportBtnDisabled = false;
                        //倒计时组件的显示
                        this.reportTimeShow = false;
                        this.reportBtnText = '已经超过可签到时间';

                    } else if (this.formData.checkInStatus == 5) {
                        //按钮不禁用
                        this.reportBtnDisabled = true;
                        //倒计时组件的显示
                        this.reportTimeShow = false;
                        this.reportBtnText = '已签离';
                    }


                })
                .catch((err) => {
                    console.log("错误信息", err);
                });
        },

        //获取照片
        getImgSrc(data) {
            this.photoLoading = true;
            get("/api/visitor/user-photo", {
                token: data.token,
                organizationId: localStorage.getItem('organizationId'),
            })
                .then((ren) => {
                    if (ren.data.code == 200) {

                        if (ren.data.data != '' && ren.data.data != null) {
                            this.base64ImageSrc = 'data:image/jpeg;base64,' + ren.data.data;
                        } else {
                            this.base64ImageSrc = null;
                        }

                        this.photoLoading = false;

                    } else {
                        Toast.fail('网络错误');
                        this.base64ImageSrc = null;
                    }
                })
                .catch((err) => {
                    console.log("错误信息", err);
                });
        },

        //查看照片
        watchPhoto() {
            console.log('查看照片', this.formData.token, this.base64ImageSrc)
            if (this.base64ImageSrc) {
                ImagePreview([this.base64ImageSrc]);
            } else {
                Toast.fail('用户未上传照片');
            }
        },
        //设置倒计时
        setCountDown(data) {
            let dq = new Date(data);
            // new Date(time)
            let interval = 0; //间隔时间
            if (this.formData.advanceCheckInMinutes) {
                interval = this.formData.advanceCheckInMinutes * 60000;
            } else {
                interval = 0;
            }

            console.log('dpdpdpdpdpdpdp', dq, new Date(Date.parse(dq) - 3600000 / 2));
            let bxs = new Date(Date.parse(dq) - interval)
            let xz = new Date();//获取当前时间
            var leave = (bxs.getTime() - xz.getTime())

            console.log('相差时间的毫秒', leave);
            if (leave > 0) {
                this.handleReportTime = leave;
                this.reportTimeShow = true;
                this.reportBtnText = '后可签到';
                // this.handleReportTime = 10000;
            } else if (leave < 0) {
                // console.log('来这里')
                this.reportBtnDisabled = false;
                this.reportTimeShow = false;
                this.reportBtnText = '签到'
            }
        },

        //倒计时结束
        reportFinish() {
            // console.log('相相相相相');
            this.reportBtnDisabled = false;
            this.reportTimeShow = false;
            this.reportBtnText = '签到'
        },

        //修改时间格式
        formatDateTime(date) {
            if (date == "" || !date) {
                return "";
            }
            var date = new Date(date);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var second = date.getSeconds();
            second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
        },
        // 预览大图
        previewImg(url) {
            // console.log('点击了预览大图',{url:url});
            if (url != "" && url != null) {
                ImagePreview([url]);
            }
        },
        //返回上一页
        onClickLeft() {

            if (this.pageState == 1) {
                this.$store.state.accessedListData.state = true;
                console.log('返回列表页', this.$store.state.accessedListData.state);
                this.$router.push({
                    name: "accessed-record",
                });
            } else {
                this.$store.state.visitListData.state = true;
                console.log('返回列表页', this.$store.state.visitListData.state);
                this.$router.push({
                    name: "visit-record",
                });
            }
        },

        //拒绝
        handleRefuse() {
            this.remarks.dialogShow = true;
        },
        //确认按钮
        handleApproval(val) {
            this.formData.organizationId = localStorage.getItem('organizationId');

            this.formData.base64Image = null;
            // let obj = {
            //     id:this.formData.id,
            //     status:null,
            //     organizationId:localStorage.getItem('organizationId'),
            //     visitorOpenId:this.formData.visitorOpenId,
            //     userRemark:null,
            // }
            if (val == 'agree') {
                Dialog.confirm({
                    message: `确认同意【${this.formData.visitorName}】的访问申请吗`,
                })
                    .then(() => {
                        this.formData.status = 5;
                        this.formData.userRemark = null;

                        this.submitForm(this.formData);
                    })
                    .catch(() => {
                        // on cancel
                    });
            } else {
                this.formData.status = 4;
                this.formData.userRemark = this.remarks.remarksText;
                this.submitForm(this.formData);
            }

        },

        //提交表单
        submitForm(data) {
            put("/api/visitor/process-visiting-application", data)
                .then((res) => {
                    if (res.data.code == 200) {
                        console.log('wwwwwwwwww', res.data.code)
                        this.onClickLeft();
                    } else {
                        console.log('不是200');
                        Toast(ren.data.data.message)
                    }
                })
                .catch((err) => {
                    console.log('请求错误')

                });
        },

        //点击翻转
        flip() {

        },

        //签到
        handleReport(text) {
            let url = '';
            if (text == '签到') {
                let currentDate = new Date();
                if (currentDate > this.formData.endTime) {
                    Toast('已经超过可签到时间');
                    return false;
                }

                url = "/api/visitor/check-in";
            } else {
                url = "/api/visitor/check-out";
            }

            this.reportBtnDisabled = true;
            let obj = {
                id: this.formData.id,
                organizationId: localStorage.getItem('organizationId')
            }

            Dialog.confirm({
                message: `确定【 ${text} 】操作吗？`
            }).then(() => {

                put(url, obj)
                    .then((res) => {
                        if (res.data.code == 200) {
                            Toast(`${text}成功`)
                            this.reportTimeShow = false;
                            if (text == '签到') {
                                this.reportBtnDisabled = false;
                                this.reportBtnText = '签离';
                            } else {
                                this.reportBtnDisabled = true;
                                this.reportBtnText = '签离成功';
                            }
                        } else {
                            console.log('不是200');
                            Toast(ren.data.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('请求错误')
                    });
            }).catch(() => {
                console.log('!!!')
            });
        },

        //切换视图
        switchView(data) {
            if (data == 0) {
                Toast('没有随访人');
            } else {
                this.$store.state.visitRecordData.formData = {
                    id: this.visitingRecordId,
                }
                this.$router.push({
                    name: "visitor-list",
                });
            }
        },


        //获取用户信息
        getUserInfo() {
            get("/api/visitor/my-info", {
                organizationId: localStorage.getItem('organizationId'),
            })
                .then((ren) => {
                    if (ren.data.code == 200) {

                        // console.log("获取用户信息",this.formData,ren);
                        // localStorage.setItem("userId", this.formData.userId); //存储顶userId
                        // this.userId = localStorage.getItem('userId');
                        // this.getImgSrc(ren.data.data);

                        let data = ren.data.data;
                        localStorage.setItem("userId", data.userId);
                        this.userId = data.userId;
                        this.getFormData();
                        // this.getImgSrc(ren.data.data);
                    } else {
                        Toast.fail(ren.data.message);
                    }
                })
                .catch((err) => {
                    console.log("错误信息", err);
                });
        },
    },
};
</script>

<style scoped lang="scss">
.visitDetails {
    .photo {
        display: flex;
        justify-content: center;

        .exhibition {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ddd;

            .img {
                width: 120%;
            }

            .icon {
                font-size: 40px;
            }

            .explain {
                font-size: 18px;
            }
        }
    }

    .formModular {
        display: flex;
        justify-content: center;
        padding-top: 15px;

        .formFrame {
            z-index: 99;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;
            position: relative;

            .state {
                .icon {
                    z-index: 1;
                    font-size: 200px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -100px;
                    margin-top: -100px;
                    opacity: 0.2;
                    animation: fadenum 5s infinite;
                }
            }
        }
    }

    .approval {
        width: 100%;
        // position: fixed;
        // left: 0;
        // bottom: 0;
        padding: 0 10px;
        padding-bottom: 20px;
        padding-top: 20px;
        box-sizing: border-box;

        .command {
            display: flex;
            justify-content: space-around;

            .btn {
                width: 100px;
                border-radius: 5px;
            }
        }
    }

    .state {
        padding-top: 30px;

        .icon {
            font-size: 120px;
            display: inline-block;
            // transform: rotateY(0deg);
            // // transition: all 0.5s;
            // transition: all 0.5s ease 0s;

            // transform: rotateY(360deg);
            animation: fadenum 5s infinite;

            &.overtime {
                font-size: 150px;
                // transform: rotate(-48deg);
            }

            &.yellow {
                //审核中
                color: #e6a23c;
            }

            &.green {
                //通过
                color: #67c23a;
            }

            &.red {
                color: #f56c6c;
            }
        }
    }

    //签到按钮
    .reportBtn {
        padding: 10px;
        padding-top: 25px;
        box-sizing: border-box;

        ::v-deep {
            .van-count-down {
                display: inline-block;
                padding-right: 10px;
                color: #fff;
            }
        }

        .btn {
            border-radius: 5px;
        }
    }

    @keyframes fadenum {
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
