import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modularData: { //模块要适用到的数据
            editData: null, //编辑时显示的数据
            jumpType: 0,//组件跳转类型0是编辑(默认);1是编辑;
        },

        visitorApplyData: {//访问申请模块数据
            formData: null,//访问申请表单数据
            // formData: {
            //     "visitorName": "王富贵",
            //     "mobile": "15698966569",
            //     "documentNo": "3212321",
            //     "visitorOrganizationName": "123123"
            // },
            accompanyingList: [],//随行人员列表

            base64ImageSrc: null,

        },

        //访问列表的数据
        visitListData: {
            router: '',//路由(未使用)
            state: false,//真就需要重新请求列表接口获取数据，假则不需要
            listData: [],//列表数据
        },

        //被访问列表的数据
        accessedListData: {
            router: '',//路由(未使用)
            state: false,//真就需要重新请求列表接口获取数据，假则不需要
            listData: [],//列表数据
        },

        //下一步的数据
        nextStepData: {
            formData: null,
            tag: null,
        },

        //我的信息模块数据
        myProfileData: {
            formData: null,
            base64ImageSrc: null,//图片
        },

        //查看申请详情的数据
        visitRecordData: {
            formData: null,
            state: 1,//1 查看别人的申请详情。 2 查看自己的申请详情
        },
        //成功页面展示的数据
        successPageData: {
            title: 'XXX成功',
            explain: 'XXX,XXXXXXXXX.',
            router: '',
        },




    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
