

import { Button, NavBar, Col, Row, Icon, Form, Field, Tabbar, TabbarItem, Toast, Picker, Image as VanImage, Popup, SwipeCell, Cell, CellGroup, PullRefresh, DatetimePicker, Search, Overlay, List, Dialog, Card, CountDown, Switch } from 'vant'
const vant = {
    install: function (Vue) {
        Vue.use(Button);
        Vue.use(NavBar);
        Vue.use(Col);
        Vue.use(Row);
        Vue.use(Icon);
        Vue.use(Form);
        Vue.use(Field);
        Vue.use(Tabbar);
        Vue.use(TabbarItem);
        Vue.use(Toast);
        Vue.use(Picker);
        Vue.use(VanImage);
        Vue.use(Popup);
        Vue.use(SwipeCell);
        Vue.use(Cell);
        Vue.use(CellGroup);
        Vue.use(PullRefresh);
        Vue.use(DatetimePicker);
        Vue.use(Search);
        Vue.use(Overlay);
        Vue.use(List);
        Vue.use(Dialog);
        Vue.use(Card);
        Vue.use(CountDown);
        Vue.use(Switch);
    }
}

export default vant