<template>
    <div class="myProfile">
        <van-nav-bar class="topNavBar" title="我的信息" />
        <div class="head">
            <div class="setting">
                <div class="photo" @click="watchPhoto">
                    <van-image
                        v-if="base64ImageSrc != null"
                        fit="fill"
                        :src="base64ImageSrc"
                        class="img"
                        :class="imgVague ? 'imgVague' : ''"
                    />

                    <van-icon v-else class="icn" name="photograph" />
                </div>

                <span class="identity" v-if="formData.type == 'EMPLOYEE'">[ 员工 ]</span>
                <span class="identity" v-else>[ 访客 ]</span>
            </div>
        </div>

        <div class="formModular">
            <div class="formFrame">
                <van-form :label-width="80">
                    <van-field
                        readonly
                        center
                        v-model="formData.name"
                        name="name"
                        label="姓名"
                        :placeholder="formData.name"
                    />

                    <van-field
                        readonly
                        center
                        v-model="formData.genderLabel"
                        name="genderLabel"
                        label="性别"
                        :placeholder="formData.genderLabel"
                    />

                    <van-field
                        readonly
                        center
                        v-model="formData.mobile"
                        name="mobile"
                        label="电话号码"
                        :placeholder="formData.mobile"
                    />

                    <van-field
                        readonly
                        center
                        v-model="formData.documentNo"
                        name="documentNo"
                        label="身份证号"
                        :placeholder="formData.documentNo"
                    />

                    <van-field
                        readonly
                        center
                        v-if="formData.visitorOrganizationName != null"
                        v-model="formData.visitorOrganizationName"
                        name="visitorOrganizationName"
                        label="单位名称"
                        :placeholder="formData.visitorOrganizationName"
                    />

                    <van-field
                        readonly
                        center
                        v-if="formData.organizationName != null"
                        v-model="formData.organizationName"
                        name="organizationName"
                        label="单位名称"
                        :placeholder="formData.organizationName"
                    />

                    <van-field
                        readonly
                        v-if="formData.organizationName == null && formData.visitorOrganizationName == null "
                        center
                        name="organizationName"
                        label="单位名称"
                    />
                </van-form>
            </div>
            <!-- v-if="formData.type != 'EMPLOYEE'" -->
            <van-button
                class="btn"
                v-if="formData.type != 'EMPLOYEE'"
                round
                @click="goEditPage"
                plain
            >编辑资料</van-button>

            <van-button class="btn red" round plain @click="loginOut">退出登录</van-button>
        </div>
        <!-- <div class="bubble" @click="goEditPage" v-if="formData.type != 'EMPLOYEE'">
            <i class="icon iconfont">&#xe80b;</i>
        </div>-->
    </div>
</template>
<script>

    import { get,post,put } from "../../utils/request";
    import {Toast} from 'vant';
    import { ImagePreview} from 'vant';
    import { Dialog } from 'vant';

    export default {
        name: "myProfile",
        components: {
            // shotModular
        },
        data() {
            return {
                base64ImageSrc:null,
                // base64ImageSrc:'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
                //图片模糊
                imgVague:true,
                formData:{
                    name:'',
                    genderLabel:'',
                    mobile:'',
                    documentNo:'',
                    visitorOrganizationName:null,
                    organizationName:null,
                    // type:'EMPLOYEE',
                    type:null,
                }
            };
        },
        watch: {
        },
        mounted() {
             console.log('$store-myProfile',this.$store.state.myProfileData,this.$store.state.myProfileData.formData);
            if(this.$store.state.myProfileData.formData != null){
                this.formData = this.$store.state.myProfileData.formData;
                this.base64ImageSrc = this.$store.state.myProfileData.base64ImageSrc;

            }else{
                // let openId = this.getRequest('openId');
                let organizationId = this.getRequest('organizationId');

                console.log('----',organizationId)
                // if(openId){
                //     localStorage.setItem("openId", openId); //存储顶部导航点击对象
                // }

                if(organizationId){
                    localStorage.setItem("organizationId", organizationId); //存储组织ID
                }
                this.getUserInfo();
            }
        },
        methods: {
            //获取用户信息
            getUserInfo(){
                // console.log("获取用户信息");
                get("/api/visitor/my-info", {
                    // openId: localStorage.getItem('openId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                    organizationId: localStorage.getItem('organizationId'),
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            this.formData = ren.data.data;
                            console.log("获取用户信息",this.formData,ren);
                            localStorage.setItem("userId", this.formData.userId); //存储顶userId
                            localStorage.setItem("userType", this.formData.type); //存储用户类型
                            if(this.formData.gender == 'M'){
                            this.formData.genderLabel = '男';
                            }else if(this.formData.gender == 'F'){
                                this.formData.genderLabel = '女';
                            }else{
                                this.formData.genderLabel = null;
                            }

                            this.formData.openId = localStorage.getItem('openId');
                            // this.formData.type = 'visitor';
                            // 'EMPLOYEE'
                            this.$store.state.myProfileData.formData = this.formData;

                            this.getImgSrc(ren.data.data);
                        }else{
                            Toast.fail(ren.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });
            },


            //获取照片
            getImgSrc(data){
                if(!data.token){
                    data.token = null;
                }
                get("/api/visitor/user-photo", {
                    token: data.token,
                    // token:'6135c765-fa27-41ed-8efd-9acf879de1bf',
                    organizationId:localStorage.getItem('organizationId'),
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            // console.log('获取照片',ren.data.data)
                            if(ren.data.data){
                                this.base64ImageSrc =  'data:image/jpeg;base64,'+ ren.data.data;
                                this.$store.state.myProfileData.base64ImageSrc = 'data:image/jpeg;base64,'+ ren.data.data;
                                this.formData.name = this.formData.name + ' ';
                                this.formData.name = this.formData.name.trim();
                            }
                        }else{
                            Toast.fail(ren.data.message);
                            this.base64ImageSrc = null;
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                    });
            },

            //点击查看照片
            watchPhoto(){
                if(this.base64ImageSrc){
                    ImagePreview([this.base64ImageSrc]);
                }else{
                    Toast.fail('未添加照片');
                }
            },

            //跳转去编辑页面
            goEditPage(){
                this.$router.push({
                    name: "edit-my-profile",
                });
                console.log('跳转是编辑页面');
                // console.log('跳转是编辑页面',this.base64ImageSrc);
            },

            //退出登录
            loginOut(){
                Dialog.confirm({
                    message: `您确定要退出登录吗？`
                }).then(() => {
                    get('/api/visitor/logout',{
                        organizationId:localStorage.getItem('organizationId')
                    }).then((ren) => {
                        if(ren.data.code == 200){
                            this.$store.state.myProfileData.formData = null;
                            this.$store.state.visitorApplyData.formData = null;
                            this.$store.state.accessedListData = {
                                router: '',//路由(未使用)
                                state: false,//真就需要重新请求列表接口获取数据，假则不需要
                                listData: [],//列表数据
                            }

                            this.$store.state.visitListData = {
                                router: '',//路由(未使用)
                                state: false,//真就需要重新请求列表接口获取数据，假则不需要
                                listData: [],//列表数据
                            }
                            this.$store.state.myProfileData.base64ImageSrc = null;
                            Toast('登出成功');
                            this.$router.push({ name: "login" });
                        }
                    }).catch((err) => {

                    })
                }).catch(() => {
                    // on cancel
                });
            }
        },
    };
</script>


<style scoped lang="scss">
.myProfile {
    .head {
        .setting {
            // background: #3f93f5;
            background: #242e47;
            // text-align: center;
            // border-radius: 0px 0px 10px 10px;
            padding: 20px 0px;
            padding-top: 40px;

            .photo {
                margin: 0 auto;
                background: #eee;
                width: 120px;
                height: 120px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .imgVague {
                    filter: blur(10px);
                }
                .icn {
                    font-size: 56px;
                    line-height: 120px;
                }
            }

            @keyframes ripple {
                0% {
                    transform: scale(1.3);
                    opacity: 0.4;
                }
                25% {
                    transform: scale(1.8);
                    opacity: 0.3;
                }
                50% {
                    transform: scale(2.3);
                    opacity: 0.2;
                }
                75% {
                    transform: scale(2.8);
                    opacity: 0.1;
                }
                100% {
                    transform: scale(3.3);
                    opacity: 0;
                }
            }
            .identity {
                display: block;
                font-size: 16px;
                color: #fff;
                padding: 10px 0;
            }
        }
    }
    .formModular {
        // display: flex;
        // justify-content: center;
        // padding-top: 20px;
        padding-top: 10px;
        .formFrame {
            margin: 0 auto;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;
        }
        .btn {
            width: 60%;
            color: #fff;
            background-color: #1989fa;
        }
        .red {
            background-color: #f56c6c;
        }
    }
    .bubble {
        position: fixed;
        bottom: 90px;
        right: 10px;
        width: 50px;
        height: 50px;
        background: #eee;
        border-radius: 50%;
        .top {
            display: inline-block;
            width: 100%;
        }
        .icon {
            font-size: 22px;
        }
        .text {
            display: block;
            font-size: 12px;
        }
    }
}
</style>
