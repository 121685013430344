<template>
    <div class="myProfile">
        <!-- <van-nav-bar title="什么" /> -->

        <van-nav-bar
            left-arrow
            left-text="我的信息"
            @click-left="onClickLeft"
            class="topNavBar"
            title="编辑信息"
        />

        <div class="formModular">
            <div class="formFrame">
                <van-form ref="formData" @failed="onFailed" :label-width="80" @submit="onSubmit">
                    <!-- 拍照组件 -->
                    <!-- <shotModular
                        ref="shotModular"
                        :shotModularText="'人脸照片'"
                        @getShotModularSrc="getShotModularSrc"
                        :base64ImageSrc="base64ImageSrc"
                    />-->

                    <div class="shotModular">
                        <div class="top">
                            <div class="exhibition" @click="handlePhotograph" :key="timer">
                                <van-image
                                    v-if="base64ImageSrc != null"
                                    fit="fill"
                                    :src="base64ImageSrc"
                                    class="img"
                                    :class="imgVague ? 'imgVague' : ''"
                                />

                                <van-icon v-else class="icn" name="photograph" />
                            </div>
                        </div>
                        <!-- <div class="explain">请点击拍摄人脸照片</div> -->
                    </div>

                    <!-- <div class="switch" v-show="base64ImageSrc != null">
                        <van-switch size="24px" v-model="imgVague" />
                    </div>-->

                    <!-- rule="name" -->
                    <van-field
                        required
                        center
                        v-model="formData.name"
                        name="name"
                        label="姓名"
                        :placeholder="formData.name"
                        :rules="rulesForm.name"
                    />

                    <van-field
                        center
                        readonly
                        clickable
                        v-model="formData.genderLabel"
                        name="gender"
                        label="性别"
                        @click="genderPicker.showPicker = true"
                    />

                    <van-popup v-model="genderPicker.showPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="genderPicker.columns"
                            @confirm="onConfirmGender"
                            @cancel="genderPicker.showPicker = false"
                        />
                    </van-popup>

                    <van-field
                        required
                        center
                        v-model="formData.mobile"
                        name="mobile"
                        label="电话号码"
                        :placeholder="formData.mobile"
                        :rules="rulesForm.mobile"
                        disabled
                    />

                    <van-field
                        center
                        v-model="formData.documentNo"
                        name="documentNo"
                        label="身份证号"
                        :placeholder="formData.documentNo"
                        :rules="rulesForm.documentNo"
                    />

                    <!-- <van-field
                        v-if="formData.visitorOrganizationName != null"
                        center
                        v-model="formData.visitorOrganizationName"
                        name="visitorOrganizationName"
                        label="单位名称"
                        :placeholder="formData.visitorOrganizationName"
                    />

                    <van-field
                        v-if="formData.organizationName != null"
                        center
                        v-model="formData.organizationName"
                        name="organizationName"
                        label="单位名称"
                        :placeholder="formData.organizationName"
                    />

                    <van-field
                        v-if="formData.organizationName == null && formData.visitorOrganizationName == null "
                        center
                        name="organizationName"
                        label="单位名称"
                    />-->

                    <van-field
                        center
                        v-model="formData.visitorOrganizationName"
                        name="visitorOrganizationName"
                        label="单位名称"
                        :placeholder="formData.visitorOrganizationName"
                    />

                    <div style="margin: 16px;">
                        <!-- <div style="margin: 16px;"> -->
                        <van-button
                            round
                            block
                            type="info"
                            native-type="submit"
                            :loading="submitBtnLoading"
                            loading-text="加载中..."
                        >保 存</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>

    import { get,post,put } from "../../utils/request";
    import {Toast} from 'vant';
    import { ImagePreview} from 'vant';


    // import shotModular from "../../components/shotModular.vue"; //引入子组件

    let documentNoRule = (rule, value, callback) => {
        // let regExp = /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/ ;
        let regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (value) {
            if (regExp.test(value) === false) {
                callback(new Error("请填写正确格式的身份证号"));
            } else {
                callback();
            }
        } else {
            callback();
        }
    };


    export default {
        name: "myProfile",
        components: {
            // shotModular
        },
        data() {
            return {
                verificationCode:{
                    btnLoading:false,
                    btnText:'发送验证码',
                    btnDisabled:false,

                },
                formData:{
                    name:'',
                    base64Image:null,
                },
                rulesForm:{
                    name:[
                        { required: true, message: '请填写用户名' }
                    ],
                    mobile:[
                        { required: true, message: '请填手机号' },
                        {
                            // pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
                            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
                            message: '手机格式错误'
                        }
                    ],
                    documentNo:[
                            {
                                validator: value => {
                                    console.log('验证中的方法',value)
                                    if(value == ''){
                                        return true
                                    }else{
                                        return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                                    .test(value)
                                    }
                                },
                                message: '请输入正确的证件号格式',
                                trigger: 'onBlur',
                            }
                    ],
                    //对比用的数据
                    contrastFormData:null,
                    //给组件使用的base64ImageSrc
                    base64ImageSrc:null,

                },


                //性别的选择器
                genderPicker:{
                    columns: ['男','女'],

                    // columns: [{
                    //     label:'男',
                    //     value:'F',
                    // },{
                    //     label:'女',
                    //     value:'M',
                    // }],
                    showPicker:false
                },
                //提交按钮加载状态
                submitBtnLoading:false,

                timer: "", //组件重新加载
                //图片模糊
                imgVague:true,
                //图片地址
                base64ImageSrc:null,

            };
        },
        watch: {

        },
        mounted() {
            console.log('$$$$$$$store-myProfile',this.$store.state.myProfileData,this.$store.state.myProfileData.formData);
            // if(this.$store.state.myProfileData.formData != null){
            //     this.formData = this.$store.state.myProfileData.formData;
            //     this.base64ImageSrc = this.$store.state.myProfileData.base64ImageSrc;

            // }else{
            //     let openId = this.getRequest('openId');
            //     let organizationId = this.getRequest('organizationId');

            //     console.log('----',openId,'----',organizationId)
            //     if(openId){
            //         localStorage.setItem("openId", openId); //存储顶部导航点击对象
            //     }

            //     if(organizationId){
            //         localStorage.setItem("organizationId", organizationId); //存储组织ID
            //     }

            //     this.getUserInfo();
            //     this.getConfig();
            // }

            // this.formData = this.$store.state.myProfileData.formData;
            this.formData = JSON.parse(JSON.stringify(this.$store.state.myProfileData.formData));
            this.base64ImageSrc = this.$store.state.myProfileData.base64ImageSrc;
            this.getConfig();
        },
        methods: {

            onClickLeft(){
                this.$router.push({
                    name: "my-profile",
                });
            },

            //获取配置信息
            getConfig(){
                console.log('----------',location.href,location.href.split('#')[0],location.href.split('#'))
                let url = location.href.split('#')[0];
                get("/api/authentication/js-sdk" ,
                {
                    url:url,
                    organizationId:localStorage.getItem('organizationId')
                    // organizationId:'3a98307c-03e9-4157-a6d5-7d9607caf963',

                })
                .then((ren) => {
                    // console.log('请求数据成功',ren.data.data);
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。  
                        appId: ren.data.data.appId, // 必填，公众号的唯一标识
                        timestamp: ren.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: ren.data.data.nonceStr, // 必填，生成签名的随机串
                        signature: ren.data.data.signature,// 必填，签名，见附录1
                        jsApiList: ['chooseImage','uploadImage']
                    });

                })
                .catch((err) => {
                    console.log("请求数据失败", err);
                });
            },



            //点击打开了摄像头
            handlePhotograph(){
                console.log('点击打开了摄像头')
                wx.ready(()=>{
                    wx.chooseImage({
                        count: 1,
                        sizeType: ['original', 'compressed'],
                        sourceType: ['album', 'camera'],
                        success: (res) => {
                            // tempFilePath可以作为 img 标签的 src 属性显示图片
                            // this.formData.imagSrc = res.localIds;
                            this.base64ImageSrc = res.localIds[0];
                            console.log("-----------------------",res,this.formData.imagSrc);
                            this.timer = !this.timer;
                            this.uploadImage();
                        },
                        fail: (error) => {
                            this.formData.imagSrc = null;
                            console.log("获取人脸照片失败：",error)
                        }
                    })
                });
            },

            //上传
            uploadImage(){
                var that = this;
                wx.uploadImage({
                    localId: [this.base64ImageSrc].toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        let mediaId = res.serverId; // 返回图片的服务器端ID
                        console.log('成功功功功功功功功',res,mediaId);
                        that.formData.mediaId = mediaId;
                    },
                    fail: function (res) {
                        // weui.toast('上传人脸图片失败，请重试:' + res);
                        console.log('上传人脸图片失败，请重试',res)
                        return;
                    }
                });
            },

            //确认性别
            onConfirmGender(value, index){
                // console.log('确认性别',value, index);
                // this.formData.gender = value;
                if(value == '男'){
                    this.formData.genderLabel = '男';
                    this.formData.gender = 'M';
                }else if(value == '女'){
                    this.formData.genderLabel = '女';
                    this.formData.gender = 'F';
                }else{
                    this.formData.genderLabel = null;
                    this.formData.gender = null;
                }
                this.genderPicker.showPicker = false;
            },


             // 验证通过提交表单
            onSubmit(){
                // this.formData.openId = 'oU6D86nt52aF3CLG6l8E_VIQScj4';
                // this.formData.type = 'visitor';

                // console.log('验证通过提交表单',this.formData);

                if(JSON.stringify(this.formData) == this.contrastFormData){
                    Toast.success('请修改信息');
                }else{
                    this.submitBtnLoading = true;

                    this.formData.organizationId = localStorage.getItem('organizationId');
                    // if(this.formData.base64Image){
                    //     delete this.formData.base64Image;
                    // }

                    console.log('YYYYYYYYYYYYYYYYYYYYYYY',this.formData,localStorage.getItem('organizationId'));
                    if(!this.formData.mediaId){
                        this.formData.mediaId = null;
                    }

                    put("/api/visitor/my-info",this.formData)
                        .then((ren) => {
                            if(ren.data.code == 200){

                                this.$store.state.myProfileData.formData = this.formData;
                                this.$store.state.myProfileData.base64ImageSrc = this.base64ImageSrc;

                                Toast.success('保存成功');
                                this.submitBtnLoading = false;
                            }else{
                                Toast.fail('网络错误');
                                this.submitBtnLoading = false;
                            }
                            // console.log("提交成功", ren);
                        })
                        .catch((err) => {
                            Toast.fail('提交失败');
                            console.log("提交失败", err);
                            this.submitBtnLoading = false;
                        });
                }

            },
            //验证不通过
            onFailed(){
                console.log('验证不通过');
            },
            //获取验证码
            getVerificationCode(){
                this.$refs.formData.validate('mobile').then(() => {
                    console.log('获取验证码验证通过');
                    // post("/api/visitor/send-phone-code?mobile="+this.formData.mobile)
                    //         .then((ren) => {
                    //             console.log("请求数据成功", ren.data.data);
                    //         })
                    //         .catch((err) => {
                    //             console.log("请求数据失败", err);
                    //         });
                    let i = 60;
                    let inter = setInterval(()=>{
                        console.log('计时',i);
                        this.verificationCode.btnText = `重新获取(${i})`
                        this.verificationCode.btnDisabled = true;
                        i--;
                        if(i == 0){
                            clearInterval(inter);
                            this.verificationCode.btnText = `发送验证码`;
                            this.verificationCode.btnDisabled = false;
                        }
                    },1000)

                }).catch(() => {
                    console.log('获取验证码验证失败');
                })
            },

            //获取照片的ID
            getShotModularSrc(mediaId){
                this.formData.mediaId = mediaId;
                console.log('获取照片的src',this.formData.mediaId);
            },
        },
    };
</script>


<style scoped lang="scss">
.myProfile {
    .formModular {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        .formFrame {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            width: 95%;

            .shotModular {
                .top {
                    display: flex;
                    justify-content: center;
                    padding: 15px 0;
                    .exhibition {
                        background: #eee;
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .imgVague {
                            // filter: blur(10px);
                        }
                        .icn {
                            font-size: 56px;
                            line-height: 120px;
                        }
                    }
                }
                .explain {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
