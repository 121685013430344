<template>
    <div class="visitorList">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="topNavBar" title="查看随行人员" />
        <van-list
            class="list"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
            <!-- <van-card
                num="2"
                price="2.00"
                desc="描述信息"
                title="商品标题"
                thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
            >
                <template #tags>
                    <van-tag plain type="danger">标签</van-tag>
                    <van-tag plain type="danger">标签</van-tag>
                </template>
                <template #footer>
                    <van-button size="mini">按钮</van-button>
                    <van-button size="mini">按钮</van-button>
                </template>
            </van-card>-->

            <!-- <van-cell
                v-for="(item,index) in listData"
                :key="index"
                clickable
                :title="item.visitorName"
                size="large"
                :label="item.visitorOrganizationName"
                @click="previewImg(item.base64ImageSrc)"
                :value="item.mobile"
                :icon="item.base64ImageSrc"
            ></van-cell>-->

            <van-cell
                v-for="(item,index) in listData"
                :key="index"
                clickable
                :title="item.visitorName"
                size="large"
                :label="item.visitorOrganizationName"
                @click="previewImg(item.base64ImageSrc)"
                :value="item.mobile"
                :icon="item.base64ImageSrc"
            ></van-cell>

            <!-- <img src="../../../static/img/fill.png" alt /> -->
        </van-list>
    </div>
</template>


<script>
    import {get,put} from "../../utils/request";
    import { ImagePreview} from 'vant';
    
    export default {
        name: "visitorList",
        components: {
        },
        data() {
            return {
                loading: false,
                finished: true,
                listData:[],//列表信息
                visitingRecordId:null,
            };
        },
        watch: {
            
        },
        mounted() {
            

            // this.visitingRecordId = JSON.parse(JSON.stringify(this.$store.state.visitRecordData.formData.id));
            let formData = JSON.parse(JSON.stringify(this.$store.state.visitRecordData.formData));
            this.visitingRecordId = this.getRequest('visitingRecordId');
            console.log('22323',formData);

            if(formData == null){
                this.$router.push({
                    name: "visit-record",
                });
            }else{
                this.visitingRecordId = formData.id;
                this.getListData();
            }
        },
        methods: {
            //导航上的箭头
            onClickLeft(){
                this.$router.go(-1)
            },

            onLoad(){
                console.log('onLoad')
            },
            

            //查看大图
            previewImg(url){
                if(url != "" && url != null){
                    ImagePreview([url]);
                }
            },
            //获取列表信息
            getListData(){
                this.finished = false;
                get("/api/visitor/accompany-visitor-list", {
                    visitingRecordId:this.visitingRecordId,
                    organizationId:localStorage.getItem('organizationId'),
                })
                    .then((ren) => {
                        if(ren.data.code == 200){
                            this.listData = [];
                            // this.listData = ren.data.data;

                            for(let i = 0; i < ren.data.data.length; i++ ){
                                if(ren.data.data[i].base64Image != null){
                                    ren.data.data[i].base64ImageSrc = 'data:image/jpeg;base64,' +  ren.data.data[i].base64Image
                                }else{
                                    ren.data.data[i].base64ImageSrc = null;
                                }
                            }
                            this.listData = ren.data.data;
                            this.finished = true;
                        }else{
                            Toast.fail('网络错误');
                            this.finished = true;
                        }
                    })
                    .catch((err) => {
                        console.log("错误信息", err);
                        this.finished = true;
                    });
            }
        },
    };
</script>



<style scoped lang="scss">
.visitorList {
    text-align: left;

    ::v-deep {
        .list {
            .van-icon {
                // font-size: 50px;
                .van-icon__image {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
}
</style>
