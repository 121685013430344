<template>
    <div class="login">
        <div class="bak"></div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                refreshing:false,
            };
        },
        methods: {
            //触底刷新
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                

                setTimeout(() => {

                    console.log("触底加载数据")

                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }

                    for (let i = 0; i < 50; i++) {
                        this.list.push(this.list.length + 1);
                    }
                    this.loading = false;

                    if (this.list.length >= 200) {
                        this.finished = true;
                    }
                }, 1000);
            },
            //下拉刷新
            onRefresh() {
                console.log("下拉刷新")
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
        },
    };
</script>


<style scoped lang="scss">
.login {
    @function listShadowOne() {
        $n: 10;
        $m: 5;
        $list: ();
        @for $j from 0 to $m {
            @for $i from 0 to $n {
                $list: $list, $i * 10vmin $j * 2 * 10vmin #fff;
            }
        }

        @return $list;
    }

    @function listShadowThree() {
        $n: 10;
        $m: 5;
        $list: ();
        @for $j from 0 to $m {
            @for $i from 0 to $n {
                $list: $list, $i * 10vmin ($j * 2-1) * 10vmin #fff;
            }
        }

        @return $list;
    }

    @function listShadowTwo() {
        $n: 10;
        $m: 5;
        $list: ();

        @for $j from 0 to $m {
            @for $i from 0 to $n {
                $list: $list, $i * 10vmin $j * 2 * 10vmin-10vmin #00f1fc;
            }
        }

        @return $list;
    }

    @function listShadowFour() {
        $n: 10;
        $m: 5;
        $list: ();

        @for $j from 0 to $m {
            @for $i from 0 to $n {
                $list: $list, $i * 10vmin ($j * 2-1) * 10vmin+10vmin #fd0130;
            }
        }

        @return $list;
    }

    .bak {
        position: relative;
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        // background: #000;
        background: #fff;
        animation: scale 5s infinite alternate;
        transform-origin: center center;
    }

    .bak::before {
        content: "";
        position: absolute;
        top: 280px;
        left: 10px;
        width: 5vmin;
        height: 5vmin;
        // background: #fff;
        box-shadow: listShadowOne();
        animation: radiusChange 5s infinite alternate;
    }

    .bak::after {
        content: "";
        position: absolute;
        top: 280px;
        left: 10px;
        width: 5vmin;
        height: 5vmin;
        // background: #fff;
        box-shadow: listShadowThree();
        animation: radiusChangeAfter 5s infinite alternate;
    }

    @keyframes radiusChange {
        25% {
            box-shadow: listShadowOne();
            border-radius: 50%;
        }
        50% {
            box-shadow: listShadowTwo();
            border-radius: 0%;
        }
        100% {
            box-shadow: listShadowOne();
            border-radius: 50%;
        }
    }

    @keyframes radiusChangeAfter {
        25% {
            box-shadow: listShadowThree();
            border-radius: 50%;
        }
        50% {
            box-shadow: listShadowFour();
            border-radius: 0%;
        }
        100% {
            box-shadow: listShadowThree();
            border-radius: 50%;
        }
    }

    @keyframes scale {
        25% {
            transform: scale(2);
        }
        50% {
            transform: scale(2);
        }
    }
}
</style>
