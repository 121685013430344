<template>
    <div id="app">
        <!-- <keep-alive
            include="visitor-list,visit-details,search-page,next-step,accompanying-list,accompanying-add"
        >-->
        <router-view />
        <!-- </keep-alive> -->
    </div>
</template>


<script>
    
    export default {
        components: {
        },
        data() {
            return {
                 pageTitle:null,
            };
        },
        watch: {
            
        },
        mounted() {

            // http://giacp.doneos.cn:82/api/visitor/landing?organizationId=3a98307c-03e9-4157-a6d5-7d9607caf963&organizationName=智控科技有限公司
            let url = location.href;
            this.pageTitle = this.getRequest('organizationName');
            // console.log('从连接内拿到什么',this.pageTitle);
            if(this.pageTitle != null ){
                document.title = this.pageTitle;
            }else{
                document.title = '丽泽智能科技访客';
            }
        },
        methods: {
            //截取url变量
            getRequest(parName){
                var str=parName.toLowerCase()+"=";
                var gvalue=null;
                var HREF=location.href;
                var upperHREF=HREF.toLowerCase();
                if(upperHREF.indexOf(str)>0) {
                    gvalue=HREF.substring(upperHREF.indexOf(str)+str.length,upperHREF.length);
                    if(gvalue.indexOf('&')>0)gvalue=gvalue.substring(0,gvalue.indexOf('&'));
                    if(gvalue.indexOf("#")>0)gvalue=gvalue.split("#")[0];
                    gvalue  =  decodeURIComponent(gvalue)
                }
                return gvalue;
            },

        },
    };
</script>



<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
