import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import common from '../utils/common.js'
Vue.use(common);

Vue.use(VueRouter)

const routes = [


    {
        path: "/jump",
        name: "jump",
        component: () =>
            import("../views/jump.vue"),
        meta: {
            title: '跳转页面'
        },
    },

    // {
    //     path: "/",
    //     name: "login",
    //     component: () =>
    //         import("../views/login.vue"),
    //     meta: {
    //         title: '登录'
    //     },
    // },

    {
        path: "/",
        name: "login",
        component: () =>
            import("../views/login4.vue"),
        meta: {
            title: '登录'
        },
    },

    {
        path: "/nav",
        name: "nav",
        component: () =>
            import("../views/Nav.vue"),
        meta: {
            title: '全球智控平台 - 顶级导航'
        },

        children: [
            {
                path: "/csx",
                name: "csx",
                component: () =>
                    import("../views/cs.vue"),
                meta: {
                    title: '测试'
                },
            },

            {
                path: '/visitor-apply',
                name: 'visitor-apply',
                // component: () => import('../views/visitorApply.vue')
                component: () => import('../components/visitorApply/visitorApply.vue'),
                hidden: false,
                meta: {
                    title: '全球智控平台 - 访问申请',
                },
            },
            {
                path: '/visit-record',
                name: 'visit-record',
                // component: () => import('../views/visitorApply.vue')
                component: () => import('../components/visitRecord/visitRecord.vue'),
                hidden: false,
                meta: {
                    title: '全球智控平台 - 访问记录',
                },
            },


            {
                path: '/accessed-record',
                name: 'accessed-record',
                // component: () => import('../views/visitorApply.vue')
                component: () => import('../components/accessedRecord/accessedRecord.vue'),
                hidden: false,
                meta: {
                    title: '全球智控平台 - 被访记录',
                },
            },


            // {
            //     path: '/my-profile',
            //     name: 'my-profile',
            //     component: () => import('../components/myProfile/myProfile.vue'),
            //     hidden: false,
            //     meta: {
            //         title: '全球智控平台 - 我的信息',
            //     },
            // },

            {
                path: '/my-profile',
                name: 'my-profile',
                component: () => import('../components/myProfile/myProfile2.vue'),
                hidden: false,
                meta: {
                    title: '全球智控平台 - 我的信息',
                },
            },

            {
                path: '/Home',
                name: 'Home',
                component: Home
            },
            {
                path: '/about',
                name: 'About',
                component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
            },
        ],
    },
    {
        path: "/accompanying-list",
        name: "accompanying-list",
        component: () => import('../components/AccompanyingList/AccompanyingList.vue'),
        meta: {
            title: '全球智控平台 - 随行人员',
        },
    },

    {
        path: "/accompanying-add",
        name: "accompanying-add",
        component: () => import('../components/AccompanyingAdd/AccompanyingAdd.vue'),
        meta: {
            title: '全球智控平台 - 添加随行人员',
        },
    },
    {
        path: "/next-step",
        name: "next-step",
        component: () => import('../components/nextStep/nextStep.vue'),
        meta: {
            title: '全球智控平台 - 下一步',
        },
    },
    {
        path: "/search-page",
        name: "search-page",
        component: () => import('../components/searchPage/searchPage'),
        meta: {
            title: '全球智控平台 - 搜索页面',
        },
    },
    {
        path: "/success-page",
        name: "success-page",
        component: () => import('../components/successPage/successPage'),
        meta: {
            title: '全球智控平台 - 成功页面',
        },
    },
    {
        path: "/visit-details",
        name: "visit-details",
        component: () => import('../components/visitDetails/visitDetails'),
        meta: {
            title: '全球智控平台 - 访问详情',
        },
    },
    {
        path: "/visitor-list",
        name: "visitor-list",
        component: () => import('../components/visitorList/visitorList'),
        meta: {
            title: '全球智控平台 - 查看随行人员',
        },
    },
    {
        path: '/edit-my-profile',
        name: 'edit-my-profile',
        component: () => import('../components/myProfile/myProfile.vue'),
        hidden: false,
        meta: {
            title: '全球智控平台 - 编辑我的信息',
        },
    },

    {
        path: "/404",
        name: "404",
        component: () => import('../views/404.vue'),
        meta: {
            title: '全球智控平台 - 404',
        },
    },

]

const router = new VueRouter({
    routes,
    // mode: "history",
})




// 路由守卫
/*
* beforeEach:从一个页面跳转到另外一个页面时触发
* to:要跳转的页面
* from:从哪个页面出来
* next:决定是否通过
*/
// router.beforeEach((to, from, next) => {
//     console.log('从哪个页面出来', from);
//     console.log('路由拦截1', to.matched, to.matched.length)
//     // 如果跳转的页面不存在，跳转到404页面
//     if (to.matched.length === 0) {
//         console.log('前往404页面')
//         next('/404')
//     } else {
//         // to.meta.keepAlive = true;
//     }
//     // console.log('路由拦截', localStorage.getItem('openId'), to.path)
//     let openId = null;
//     if (localStorage.getItem('openId') != 'null') {
//         openId = localStorage.getItem('openId');
//     }

//     // let openId = JSON.parse(localStorage.getItem('openId'));
//     console.log('路由拦截2', openId);
//     if (openId && openId != null) {
//         next()
//     } else {
//         if (to.path === "/" || to.path == '/my-profile') {
//             next()
//         } else {
//             next('/')
//         }

//     }
// })



export default router
