
<template>
    <div class="successPage">
        <div class="content">
            <van-icon class="icon" name="passed" />
            <p class="title">{{title}}</p>
            <!-- <p class="explain">{{explain}}</p> -->

            <van-button @click="handleConfirm" class="btn" type="info">关 闭</van-button>
        </div>
    </div>
</template>


<script>
    export default {
        name: "successPage",
        components: {
        },
        data() {
            return {
                title:'XXX成功',
                explain:'XXX,XXXXXXXXX.',
                router:'success-page'
            };
        },
        watch: {
        },
        mounted() {
            this.title = this.$store.state.successPageData.title;
            this.explain = this.$store.state.successPageData.explain;
            this.router = this.$store.state.successPageData.router;

            this.$store.state.nextStepData.formData = null;
			this.$store.state.nextStepData.tag = null;
            this.$store.state.visitorApplyData.formData = null;

        },
        methods: {
            handleConfirm(){
                this.$router.push({
                    name: this.router,
                });
            }
        },
    };
</script>


<style scoped lang="scss">
.successPage {
    .content {
        padding-top: 200px;
        .icon {
            font-size: 80px;
            color: #07c160;
        }
        .title {
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .explain {
            font-size: 16px;
            margin-top: 10px;
            color: #a5a5a5;
            margin-bottom: 10px;
        }
        .btn {
            width: 160px;
            border-radius: 5px;
        }
    }
}
</style>
