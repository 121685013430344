<template>
    <div class="shotModular">
        <div class="top">
            <div class="exhibition" @click="handlePhotograph">
                <van-image v-if="imagSrc != null" fit="fill" :src="imagSrc[0]" />

                <van-icon v-else class="icn" name="photograph" />
            </div>
        </div>
        <div class="explain">{{shotModularText}}</div>
    </div>
</template>


<script>
    // import wx from 'weixin-js-sdk'
    import {get} from "../utils/request";
    import {Toast} from 'vant' 
    export default {
        name: "shotModular",
        props: ["shotModularText",'base64ImageSrc'],
        components: {
        },
        data() {
            return {
                imagSrc:null,
                // imagSrc:"https://img01.yzcdn.cn/vant/cat.jpeg",
                isPhoto:false,

                // 要传递的url
                dataURL:null,
                //拍摄照片下方的说明文字
                //组织id
                organizationId:null,
               
            };
        },
        watch: {
            base64ImageSrc(val, newval) {
                // console.log('watchwatchwatchwatch11111',val, newval);
                this.imagSrc = ['data:image/jpeg;base64,'+ val]
            },
        },
        mounted() {
            console.log('我是拍照组件里执行的方法',);
            this.organizationId = localStorage.getItem('organizationId');
            if(this.base64ImageSrc != null){
                this.imagSrc = ['data:image/jpeg;base64,'+this.base64ImageSrc]
            }
            this.getConfig();
        },
        methods: {

            //获取配置信息
            getConfig(){
                console.log('----------',location.href,location.href.split('#')[0],location.href.split('#'))
                let url = location.href.split('#')[0];
                
                get("/api/authentication/js-sdk" ,
                {
                    url:url,
                    organizationId:this.organizationId
                    
                })
                .then((ren) => {
                    // console.log('请求数据成功',ren.data.data);
                    wx.config({  
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。  
                        appId: ren.data.data.appId, // 必填，公众号的唯一标识  
                        timestamp: ren.data.data.timestamp, // 必填，生成签名的时间戳  
                        nonceStr: ren.data.data.nonceStr, // 必填，生成签名的随机串  
                        signature: ren.data.data.signature,// 必填，签名，见附录1  
                        jsApiList: ['chooseImage','uploadImage']
                    });
                    
                })
                .catch((err) => {
                    console.log("请求数据失败", err);
                });
            },



            // //点击打开了摄像头
            // handlePhotograph(){
                
            //     // this.imagSrc = 'weixin://resourceid/deafbd75c4db478d4ea97c47cc8746bc';
            //     // this.uploadImage();
            //     let url = location.href.split('#')[0];
            //     get("/api/authentication/js-sdk" ,
            //     {
            //         url:url,
            //         organizationId:this.organizationId
            //     })
            //     .then((ren) => {
            //         wx.config({  
            //             debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。  
            //             appId: ren.data.data.appId, // 必填，公众号的唯一标识  
            //             timestamp: ren.data.data.timestamp, // 必填，生成签名的时间戳  
            //             nonceStr: ren.data.data.nonceStr, // 必填，生成签名的随机串  
            //             signature: ren.data.data.signature,// 必填，签名，见附录1  
            //             jsApiList: ['chooseImage','uploadImage']
            //         });

            //         // console.log('WWWWWWWWWW',ren.data.data,wx.config);
            //         wx.ready(()=>{
            //             wx.chooseImage({
            //                 count: 1,
            //                 sizeType: ['original', 'compressed'],
            //                 sourceType: ['album', 'camera'],
            //                 success: (res) => {
            //                     // tempFilePath可以作为 img 标签的 src 属性显示图片
            //                     this.imagSrc = res.localIds;
            //                     console.log("-----------------------",res,this.imagSrc);
            //                     // this.imageUrlToBase64(this.imagSrc);
            //                     this.uploadImage();
            //                 },
            //                 fail: (error) => {
            //                     this.imagSrc = null;
            //                     console.log("获取人脸照片失败：",error)
            //                 }
            //             })
            //         });
            //     })
            //     .catch((err) => {
            //         console.log("请求数据失败", err);
            //     });
            // },


            //点击打开了摄像头
            handlePhotograph(){
                
                wx.ready(()=>{
                    wx.chooseImage({
                        count: 1,
                        sizeType: ['original', 'compressed'],
                        sourceType: ['album', 'camera'],
                        success: (res) => {
                            // tempFilePath可以作为 img 标签的 src 属性显示图片
                            this.imagSrc = res.localIds;
                            console.log("-----------------------",res,this.imagSrc);
                            // this.imageUrlToBase64(this.imagSrc);
                            this.uploadImage();
                        },
                        fail: (error) => {
                            this.imagSrc = null;
                            console.log("获取人脸照片失败：",error)
                        }
                    })
                });
            },


            //上传
            uploadImage(){
                var that = this;
                wx.uploadImage({
                    localId: this.imagSrc.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        var mediaId = res.serverId; // 返回图片的服务器端ID
                        console.log('成功功功功功功功功',res,mediaId);
                        that.$emit("getShotModularSrc",mediaId);
                    },
                    fail: function (res) {
                        // weui.toast('上传人脸图片失败，请重试:' + res);
                        console.log('上传人脸图片失败，请重试',res)
                        return;
                    }
                });
            },
        },
    };
</script>


<style scoped lang="scss">
.shotModular {
    .top {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        .exhibition {
            background: #eee;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .icn {
                font-size: 56px;
                line-height: 120px;
            }
        }
    }
    .explain {
        font-size: 16px;
    }
}
</style>
